import React, { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Checkbox,
  Typography,
} from '@mui/material';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import HailIcon from '@mui/icons-material/Hail';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIconOutlined from '@mui/icons-material/EditOutlined';
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import TocIconOutlined from '@mui/icons-material/TocOutlined';
import StarsIcon from '@mui/icons-material/Stars';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

import { useNavigate } from 'react-router-dom';

import { Table, ConfirmationModal, Hooks, DataNotFound } from '../../../core';
import CustomerViewModal from './customer_view_modal';
import { getStatusChip, getPaymentStatusChip } from '../customer_utils';
import PaymentDrawer from './customer_payment_drawer';
import CustomerHoldDrawer from './customer_hold_drawer';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { DELIVERY_METHOD, TIFFIN, STORAGE_KEYS } from '../../../js/lib/constants';
import CustomerTiffinGoodsDrawer from './customer_tiffin_goods_drawer';

const headCells = [{
  id: 'checkbox',
  title: 'Select',
}, {
  id: 'name',
  title: 'Name',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'phoneNumber',
  title: 'Phone Number',
}, {
  id: 'address',
  title: 'Address',
}, {
  id: 'tiffin',
  title: 'Tiffin',
}, {
  id: 'action',
  title: 'Actions',
}];

const { useLazyGetTiffinSubscriberQuery, useUpdateTiffinSubscriberMutation, useDeleteTiffinSubscriberMutation } = tiffinsApi;
const { useSnackBarNotification } = Hooks;

export default function CustomerListComponent({
  rows,
  setRows,
  isLoading,
  selectedItems,
  setSelectedItems,
  selectAllChecked,
  selectAllHandler,
  page,
  setPage
}) {
  const navigate = useNavigate();
  const [showTiffinViewModal, setShowTiffinViewModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showPaymentDrawer, setShowPaymentDrawer] = useState(false);
  const [showHoldConfirmationModal, setShowHoldConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [showTiffinItemsDrawer, setShowTiffinItemsDrawer] = useState(false);
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [trigger, { data, isSuccess }] = useLazyGetTiffinSubscriberQuery();

  const [deleteCustomer, {
    data: deleteCustomerData,
    isSuccess: deleteCustomerIsSuccess,
    isError: deleteCustomerIsError,
    error: deleteCustomerError,
  }] = useDeleteTiffinSubscriberMutation();

  const [updateCustomer, {
    data: updateCustomerData,
    isSuccess: updateCustomerIsSuccess,
    isError: updateCustomerIsError,
    error: updateCustomerError,
  }] = useUpdateTiffinSubscriberMutation();

  useEffect(() => {
    if (deleteCustomerIsSuccess && deleteCustomerData) {
      successSnackBar({ message: 'Customers deleted successfully' });
      const newRows = rows.filter((row) => row?.id !== deleteCustomerData?.data?.id);
      if (!newRows.length && Number(page) > 1) {
        setPage(page - 1);
      }
      setRows(newRows);
    } if (deleteCustomerIsError && deleteCustomerError) {
      errorSnackBar({ message: deleteCustomerError?.data?.errorMessage });
    }
  }, [deleteCustomerIsSuccess, deleteCustomerIsError, deleteCustomerError]);

  useEffect(() => {
    if (updateCustomerIsSuccess && updateCustomerData) {
      successSnackBar({ message: 'Customers updated successfully' });
      setRows(rows.map((row) => row?.id === updateCustomerData?.data?.id ? updateCustomerData?.data : row));
    } if (updateCustomerIsError && updateCustomerError) {
      errorSnackBar({ message: updateCustomerError?.data?.errorMessage });
    }
  }, [updateCustomerIsSuccess, updateCustomerIsError, updateCustomerError]);

  useEffect(() => {
    if (data?.data) {
      setRows(rows?.map(item => item.id === data?.data?.id ? data?.data : item));
    }
  }, [trigger, data, isSuccess]);

  const menuOptions = (item) => [{
    id: `${item?.id}_view`,
    title: 'View',
    icon: <VisibilityOutlined fontSize="small" />,
    disabled: false,
    action: () => {
      setShowTiffinViewModal(true);
    }
  }, {
    id: `${item?.id}_edit`,
    title: 'Edit',
    icon: <EditIconOutlined fontSize="small" />,
    disabled: selectedItem?.status === TIFFIN.STATUS.CANCEL,
    action: () => {
      navigate(selectedItem?.id);
    }
  }, {
    id: `${item?.id}_duplicate`,
    title: 'Duplicate',
    icon: <ContentCopyOutlinedIcon fontSize="small" />,
    disabled: false,
    action: () => {
      const copiedItem = { ...selectedItem };
      delete copiedItem?.id;
      delete copiedItem?.createdAt;
      delete copiedItem?.createdBy;
      delete copiedItem?.modifiedAt;
      delete copiedItem?.modifiedBy;
      sessionStorage.setItem(STORAGE_KEYS.CUSTOMER, JSON.stringify(copiedItem));
      navigate('/customers/new');
    }
  }, {
    id: `${item?.id}_cancel`,
    title: selectedItem?.status === TIFFIN.STATUS.CANCEL ? 'Reactivate' : 'Cancel',
    icon: selectedItem?.status === TIFFIN.STATUS.CANCEL ? <RestartAltOutlinedIcon fontSize="small" /> : <CancelOutlinedIcon fontSize="small" />,
    action: () => {
      if (selectedItem?.status === TIFFIN.STATUS.CANCEL) {
        setShowResetConfirmationModal(true);
      } else {
        setShowCancelConfirmationModal(true);
      }
    }
  }, {
    id: `${item?.id}_delete`,
    title: 'Delete',
    icon: <DeleteOutlineOutlinedIcon fontSize="small" />,
    disabled: false,
    action: () => {
      setShowDeleteConfirmationModal(true);
    }
  }, {
    id: `${item?.id}_tiffin_items`,
    title: 'Tiffin Items',
    icon: <TocIconOutlined fontSize="small" />,
    disabled: false,
    action: () => {
      setShowTiffinItemsDrawer(true);
    }
  }, {
    id: `${item?.id}_hold`,
    title: 'Hold',
    icon: <PauseCircleOutlineOutlinedIcon fontSize="small" />,
    disabled: false,
    action: () => {
      setShowHoldConfirmationModal(true);
    }
  }, {
    id: `${item?.id}_payments`,
    title: 'Payments',
    icon: <AttachMoneyOutlinedIcon fontSize="small" />,
    disabled: false,
    action: () => {
      setShowPaymentDrawer(true);
    }
  }];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const processedItems = rows.map((item) => {
    const { id, customer, route, shipping } = item;

    const result = {
      checkbox: <Checkbox
        checked={selectedItems?.includes(item?.id)}
        onClick={(event) => {
          if (event.target.checked) {
            setSelectedItems([...selectedItems, item?.id])
          } else {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item?.id))
          }
        }} />,
      name: <>
        <Typography>{`${customer?.firstName} ${customer?.lastName}`}</Typography>
        {item?.paymentStatus === TIFFIN.PAYMENTS.PENDING && getPaymentStatusChip(item)}
      </>,
      status: getStatusChip(item),
      phoneNumber: customer?.phoneNumber?.number || '--',
      address: (
        <>
          <Tooltip title={shipping} placement="left">
            {shipping === DELIVERY_METHOD.PICK_UP ? (
              <HailIcon sx={{ fontSize: 22, mr: 1, border: '1px solid black', borderRadius: '10%' }} />
            ) : (
              <DeliveryDiningIcon sx={{ fontSize: 22, mr: 1, border: '1px solid black', borderRadius: '10%' }} />
            )}
          </Tooltip>
          {customer?.unit ? `${customer?.unit} - ` : ''}
          {customer?.address?.description}
        </>
      ),
      tiffin: <>
        {item?.tiffin?.name}
        {item?.itemsChanged && <Tooltip title={'Special tiffin - Items customized'} placement="top">
          <StarsIcon sx={{ fontSize: 14, color: 'orange' }} />
        </Tooltip>}
      </>,
      action: <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event) => {
            setSelectedItem(item);
            handleClick(event);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuOptions(item).map(({ id, title, icon, action, disabled }) => [
            <MenuItem
              disabled={disabled}
              key={id}
              onClick={() => {
                action();
                handleClose();
              }}>
              <ListItemIcon>
                {icon}
              </ListItemIcon>
              {title}
            </MenuItem>,
            (title === "Delete") && <Divider key={`divider-${id}`} />
          ])}
        </Menu>
      </div>,
      id,
    };

    return result;
  });

  const deleteConfirmationModal = <ConfirmationModal
    open={showDeleteConfirmationModal}
    handleClose={() => setShowDeleteConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{`Are you sure want to delete this customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Delete"
    actionHandler={() => {
      deleteCustomer({ id: selectedItem?.id });
      setShowDeleteConfirmationModal(false);
    }}
  />;

  const resetConfirmationModal = <ConfirmationModal
    open={showResetConfirmationModal}
    handleClose={() => setShowResetConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{`Are you sure want to reset the subscription of this customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Update"
    actionHandler={() => {
      setShowResetConfirmationModal(false);
      updateCustomer({ id: selectedItem?.id, data: { ...selectedItem, status: TIFFIN.STATUS.ACTIVE } });
    }}
  />;

  const cancelConfirmationModal = <ConfirmationModal
    open={showCancelConfirmationModal}
    handleClose={() => setShowCancelConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{`Are you sure want to cancel the subscription of this customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Update"
    actionHandler={() => {
      setShowCancelConfirmationModal(false);
      updateCustomer({ id: selectedItem?.id, data: { ...selectedItem, status: TIFFIN.STATUS.CANCEL } });
    }}
  />;

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: '16px' }}>
        <Grid item xs={12}>
          <Table
            selectAllHandler={selectAllHandler}
            selectAllChecked={selectAllChecked}
            headers={headCells}
            data={processedItems}
            isLoading={isLoading}
            selectedRow={() => { }} />
        </Grid>
        <Grid item xs={12}>
          {!rows?.length && <DataNotFound title="customers" />}
        </Grid>
      </Grid>
      <PaymentDrawer
        fetchSubscriber={trigger}
        open={showPaymentDrawer}
        viewOnly={false}
        item={selectedItem}
        handleClose={() => setShowPaymentDrawer(false)}
      />
      <CustomerHoldDrawer
        fetchCustomer={trigger}
        open={showHoldConfirmationModal}
        viewOnly={false}
        item={selectedItem}
        handleClose={() => setShowHoldConfirmationModal(false)}
      />
      <CustomerViewModal
        open={showTiffinViewModal}
        handleClose={() => setShowTiffinViewModal(false)}
        item={selectedItem} />
      <CustomerTiffinGoodsDrawer
        item={selectedItem}
        open={showTiffinItemsDrawer}
        handleClose={() => {
          setSelectedItem(null);
          setShowTiffinItemsDrawer(false);
        }}
      />
      {resetConfirmationModal}
      {deleteConfirmationModal}
      {cancelConfirmationModal}
    </>
  );
}
