import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
    InputAdornment,
    Grid,
    Typography,
} from '@mui/material';
import { BaseTextField, TuiAutoComplete, Hooks } from '../../../core/index';
import { googleMapsApi, tiffinsApi } from '../../../js/slices/api_slices';

const { useGetTiffinsQuery } = tiffinsApi;
const { useGetPlacesQuery } = googleMapsApi;

export default function RouteStopForm({ register, errors, viewOnly, control, watch, getValues, item }) {
    const [addressInputValue, setAddressInputValue] = useState(getValues('address.description') || '');
    const debouncedAddressInput = Hooks.useDebounce(addressInputValue, 500);
    // Tiffin selection states
    const [tiffinInputValue, setTiffinInputValue] = useState(getValues('customer.tiffin.name') || '');
    const debouncedTiffinInput = Hooks.useDebounce(tiffinInputValue, 500);

    const { data: addressData,
        isLoading: addressLoading } = useGetPlacesQuery({
            input: debouncedAddressInput,
        }, { refetchOnMountOrArgChange: true, skip: addressInputValue ? false : true });

    const { data: tiffinData, isLoading: tiffinIsLoading } = useGetTiffinsQuery({
        page: 1,
        pageSize: 20,
        contains: { name: debouncedTiffinInput },
    }, { refetchOnMountOrArgChange: true });

    return (
        <Grid
            container
            sx={{
                backgroundColor: 'white',
                height: '100%',
                p: 2
            }}>
            <Grid
                item
                container
                xs={12}
                spacing={2}
                sx={{
                    p: 1.2,
                    borderRadius: '20px',
                    border: '1px solid #E0E0E0',
                }}
            >
                <Grid item xs={12}>
                    <Typography>Details</Typography>
                </Grid>
                <Grid item container xs={12} sx={{ mt: 1 }} spacing={1}>
                    <Grid item xs={3}>
                        <BaseTextField
                            id='customer.firstName'
                            size="small"
                            name="customer.firstName"
                            label="First Name"
                            disabled={viewOnly}
                            errors={errors}
                            value={watch('customer.firstName')}
                            validate={register('customer.firstName', {
                                required: 'First name is required',
                                maxLength: {
                                    value: 1000,
                                    message: 'Length must be less than 500',
                                },
                            },
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <BaseTextField
                            id='customer.lastName'
                            size="small"
                            name="customer.lastName"
                            label="Last Name"
                            required={false}
                            disabled={viewOnly}
                            value={watch('customer.lastName')}
                            errors={errors}
                            validate={register('customer.lastName', {
                                maxLength: {
                                    value: 1000,
                                    message: 'Length must be less than 500',
                                },
                            },
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <BaseTextField
                            id='customer.phoneNumber'
                            size="small"
                            name="customer.phoneNumber"
                            label="Phone Number"
                            disabled={viewOnly}
                            required={false}
                            errors={errors}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                            }}
                            validate={register('customer.phoneNumber.number', {
                                maxLength: {
                                    value: 10,
                                    message: 'Length must be 10',
                                },
                            },
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <BaseTextField
                            id='customer.email'
                            size="small"
                            name="customer.email"
                            label="Email"
                            required={false}
                            type="customer.email"
                            errors={errors}
                            validate={register('customer.email', {
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'Entered value does not match customer.email format',
                                },
                            },
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} sx={{ mt: 1 }} spacing={1}>
                    <Grid item xs={4}>
                        <Controller
                            control={control}
                            rules={{ required: 'Address must be valid' }}
                            name="address"
                            defaultValue={item?.address}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TuiAutoComplete
                                        name="address"
                                        id="address"
                                        loading={addressLoading}
                                        freeSolo
                                        errors={errors}
                                        onChange={(event, item) => {
                                            onChange(item);
                                        }}
                                        inputValue={addressInputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setAddressInputValue(newInputValue);
                                        }}
                                        value={value}
                                        label="Address"
                                        labelKey='description'
                                        options={addressData?.data?.items}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <BaseTextField
                            id='customer.deliveryInstructions'
                            size="small"
                            name="customer.deliveryInstructions"
                            label="Drop Point"
                            disabled={viewOnly}
                            errors={errors}
                            required={false}
                            validate={register('customer.deliveryInstructions', {
                                maxLength: {
                                    value: 500,
                                    message: 'Length must be less than 500',
                                },
                            },
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Controller
                            control={control}
                            rules={{ required: 'Tiffin must be valid' }}
                            name="customer.tiffin"
                            defaultValue={item?.customer?.tiffin?.name}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <TuiAutoComplete
                                        loading={tiffinIsLoading}
                                        name="selectTiffin"
                                        id="selectTiffin"
                                        errors={errors}
                                        onChange={(event, item) => {
                                            if (item) {
                                                onChange(item);
                                            }
                                        }}
                                        inputValue={tiffinInputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setTiffinInputValue(newInputValue);
                                        }}
                                        value={value}
                                        labelKey='name'
                                        size='small'
                                        label="Select Tiffin"
                                        noOptionsText="No Tiffin Exists"
                                        options={tiffinData?.data?.items}
                                    />
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
