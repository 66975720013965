import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Checkbox,
  Grid2 as Grid,
  Typography,
  Tooltip,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';

import HelpIcon from '@mui/icons-material/Help';
import { BaseTextField, TuiAutoComplete, Hooks, DateTimePicker, CoreButton } from '../../../core/index';
import { googleMapsApi, employeesApi } from '../../../js/slices/api_slices';
import { USERS, ROUTE } from '../../../js/lib/constants';
import { useSelector } from 'react-redux';

const { useGetPlacesQuery } = googleMapsApi;
const { useGetEmployeesQuery } = employeesApi;

export default function RouteForm({ viewOnly, item }) {
  const { register, control, watch, formState: { errors }, getValues } = useFormContext();
  const navigate = useNavigate();
  const [newDriverInputValue, setNewDriverInputValue] = useState('');
  const debouncedDriverInputValue = Hooks.useDebounce(newDriverInputValue, 500);
  const settings = useSelector((store) => store?.settings?.settings);
  const [startAddressInputValue, setStartAddressInputValue] = useState('');
  const [finishAddressInputValue, setFinishAddressInputValue] = useState('');
  const debouncedStartAddressInput = Hooks.useDebounce(startAddressInputValue, 500);
  const debouncedFinishAddressInput = Hooks.useDebounce(finishAddressInputValue, 500);

  const { data: startAddressData,
    isLoading: startAddressLoading } = useGetPlacesQuery({
      input: debouncedStartAddressInput,
    }, { refetchOnMountOrArgChange: true });

  const { data: finishAddressData,
    isLoading: finishAddressLoading } = useGetPlacesQuery({
      input: debouncedFinishAddressInput,
    }, { refetchOnMountOrArgChange: true });

  const { data: existingDriversData,
    isLoading: existingDriversIsLoading } = useGetEmployeesQuery({
      type: USERS.TYPES.EMPLOYEE.DRIVER,
      status: Object.values(USERS.STATUS).filter((item) => item !== USERS.STATUS.BLOCKED),
      contains: { 'firstName': debouncedDriverInputValue, 'lastName': debouncedDriverInputValue },
    }, { refetchOnMountOrArgChange: true });

  return <><Grid
    container
    size={12}
    justifyContent="center"
    sx={{
      backgroundColor: 'white',
      height: '100%',
      p: 2,
    }}>
    <Grid size={6} container spacing={3} sx={{
      p: 4,
      mt: 1,
      border: '1px solid #e0e0e0',
      borderColor: 'grey.300',
      borderRadius: '5px',
    }}>
      <Grid size={12}>
        <BaseTextField
          id='name'
          size="small"
          name="name"
          label="Route Name"
          value={watch('name')}
          disabled={viewOnly}
          errors={errors}
          required={true}
          validate={register('name', {
            required: 'Name must be valid',
            maxLength: {
              value: 100,
              message: 'Name must be less than 100 characters'
            },
            validate: (value) => {
              const trimmedValue = value?.trim().toLowerCase();
              if (trimmedValue === 'none') {
                return 'Name "None" is not allowed';
              }
              return true;
            }
          })}
        />
      </Grid>
      <Grid size={12} container spacing={1}>
        <Grid size={6}>
          <Controller
            control={control}
            rules={{ required: 'Invalid Time' }}
            name="startTime"
            render={({ field: { onChange, value } }) => {
              return (
                <DateTimePicker
                  control={control}
                  onChange={(event) => {
                    onChange(event);
                  }}
                  disabled={viewOnly}
                  required={true}
                  errors={errors}
                  label="Start Date & Time"
                  value={value}
                  minDate={moment().startOf('day')}
                />
              );
            }
            }
          />
        </Grid>
        <Grid size={6}>
          <Controller
            control={control}
            rules={{ required: 'Driver must be valid' }}
            name="driver"
            render={({ field: { onChange, value } }) => {
              return (
                <TuiAutoComplete
                  name="driver"
                  id="driver"
                  loading={existingDriversIsLoading}
                  freeSolo
                  errors={errors}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  inputValue={newDriverInputValue}
                  onInputChange={(event, newInputValue) => {
                    if (newInputValue !== newDriverInputValue) {
                      setNewDriverInputValue(newInputValue);
                    }
                  }}
                  disabled={viewOnly || existingDriversIsLoading || existingDriversData?.data?.items?.length === 0}
                  value={value}
                  label="Select Driver"
                  labelKey='firstName'
                  options={existingDriversData?.data?.items}
                  helperText={existingDriversData?.data?.items?.length === 0 ?
                    <Typography
                      variant="h7"
                      fontWeight="bold"
                      display="inline">
                      No Driver Exists
                      <Box ml={1} display="inline">
                        <CoreButton
                          sx={{
                            p: 0,
                            m: 0,
                            color: 'secondary.contrastText',
                            textDecoration: 'underline'
                          }}
                          fullWidth={false}
                          disableRipple
                          onClickHandler={() => navigate('/employees/new')}
                        >
                          Add Driver
                        </CoreButton>
                      </Box>
                    </Typography>
                    : ''}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid size={12} container spacing={1}>
        <Grid size={6}>
          <Controller
            control={control}
            rules={{ required: 'Address must be valid' }}
            name="startAddress"
            render={({ field: { onChange, value } }) => {
              return (
                <TuiAutoComplete
                  disabled={viewOnly}
                  name="startAddress"
                  id="startAddress"
                  loading={startAddressLoading}
                  freeSolo
                  errors={errors}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  inputValue={startAddressInputValue}
                  onInputChange={(event, newInputValue) => {
                    setStartAddressInputValue(newInputValue);
                  }}
                  value={value}
                  label="Start Address"
                  labelKey='description'
                  options={startAddressData?.data?.items}
                />
              );
            }}
          />
        </Grid>
        <Grid size={6}>
          <Controller
            control={control}
            name="finishAddress"
            render={({ field: { onChange, value } }) => {
              return (
                <TuiAutoComplete
                  disabled={viewOnly}
                  name="finishAddress"
                  id="finishAddress"
                  loading={finishAddressLoading}
                  freeSolo
                  errors={errors}
                  onChange={(event, item) => {
                    onChange(item);
                  }}
                  required={false}
                  inputValue={finishAddressInputValue}
                  onInputChange={(event, newInputValue) => {
                    setFinishAddressInputValue(newInputValue);
                  }}
                  value={value}
                  label="Finish Address"
                  labelKey='description'
                  options={finishAddressData?.data?.items}
                />
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid size={12} container spacing={1} sx={{
        border: '1px solid #e0e0e0',
        borderColor: 'grey.400',
        borderRadius: '5px',
      }}>
        <Grid size={6} container>
          <Typography sx={{ p: 1, pr: 0 }}>Route Type</Typography>
          <Tooltip
            arrow
            placement="top"
            title='Permanent route are created automatically every day'
          >
            <HelpIcon fontSize='small' color='secondary' sx={{ mt: 1.2 }} />
          </Tooltip>
        </Grid>
        <Grid size={6}>
          <Controller
            rules={{ required: 'Invalid Type' }}
            control={control}
            name="type"
            render={({ field }) => {
              return (
                <RadioGroup
                  {...field}
                  onChange={(event, value) => {
                    field.onChange(value);
                  }}
                  row
                  aria-labelledby="via-buttons-group-label"
                  name="type"
                >
                  {Object.entries((ROUTE.TYPES)).map(([key, value]) => {
                    return <FormControlLabel
                      key={key}
                      value={value}
                      control={
                        <Radio sx={{
                          '&, &.Mui-checked': {
                            color: 'secondary.contrastText',
                          },
                        }} />
                      }
                      label={value} />
                  })}
                </RadioGroup>
              );
            }}
          />
        </Grid>
      </Grid>
      <Grid
        size={12}
        spacing={1}
        container
        sx={{
          border: '1px solid #e0e0e0',
          borderColor: 'grey.400',
          borderRadius: '5px',
        }}>
        <Grid size={6} container>
          <Typography sx={{ p: 1, pr: 0 }}>Reverse Route</Typography>
          <Tooltip
            arrow
            placement="top"
            title='Reverse the route direction if start and finish address are the same'
          >
            <HelpIcon fontSize='small' color='secondary' sx={{ mt: 1.2 }} />
          </Tooltip>
        </Grid>
        <Grid size={6}>
          <Controller
            control={control}
            name="reverse"
            render={({ field }) => (
              <Checkbox
                id='reverse'
                name='reverse'
                {...field}
                checked={field.value}
                sx={{
                  pl: 0,
                  color: 'secondary.contrastText',
                  '&.Mui-checked': {
                    color: 'secondary.contrastText',
                  },
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  </>
}
