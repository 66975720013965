import React, { useEffect, useState } from 'react';
import { Alert, Box, Grid, Typography, Paper, IconButton, Divider, Button, Chip } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';

import BasePagination from '../../../core/base_pagination';
import { tiffinsApi, deliveryRoutesApi } from '../../../js/slices/api_slices';
import CustomerListComponent from '../components/customer_list_component';
import { TuiAppBar, Hooks, ConfirmationModal, TuiSpinner, TuiAutoComplete, CoreButton } from '../../../core';
import CustomerFilterModal from '../components/customer_filter_modal';
import { DELIVERY_METHOD, STORAGE_KEYS, TIFFIN } from '../../../js/lib/constants';

const { useGetTiffinSubscribersQuery, useLazyGetTiffinSubscribersQuery, useUpdateTiffinSubscriberMutation, useDeleteTiffinSubscriberMutation, useGetTiffinsQuery, useGetTiffinSubscriberStatsQuery } = tiffinsApi;
const { useResponsivePageSize, usePageNumberAndSearch, useDebounce, useSnackBarNotification } = Hooks;
const { useGetDeliveryRoutesQuery } = deliveryRoutesApi;

const showAllTiffinsText = 'All';
const showSpecialTiffinsText = 'Special';
const showNonSpecialTiffinsText = 'Non Special';

export default function CustomersView() {
  const [pageSize, setPageSize] = useResponsivePageSize();
  const [selectedItems, setSelectedItems] = useState([]);
  const roles = useSelector((store) => store?.roles.roles);
  const [newRoute, setNewRoute] = useState(null);
  const [newRouteInputValue, setNewRouteInputValue] = useState('');
  const [newTiffin, setNewTiffin] = useState(null);
  const [newTiffinInputValue, setNewTiffinInputValue] = useState('');
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const [showChangeRouteConfirmationModal, setShowChangeRouteConfirmationModal] = useState(false);
  const [showChangeTiffinConfirmationModal, setShowChangeTiffinConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const [page, setPage, searchText, setSearchText] = usePageNumberAndSearch();
  const [rows, setRows] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  let storageFilter = sessionStorage.getItem(STORAGE_KEYS.CUSTOMER_FILTER);
  if (storageFilter) {
    storageFilter = JSON.parse(storageFilter);
  }
  const [filterObject, setFilterObject] = useState({
    'status': storageFilter?.status || '',
    'shipping': storageFilter?.shipping || '',
    'route': storageFilter?.route || '',
    'startDate': storageFilter?.startDate || '',
    'endDate': storageFilter?.endDate || '',
    'tiffin': storageFilter?.tiffin || '',
    'itemsChanged': storageFilter?.itemsChanged || null,
    'paymentStatus': storageFilter?.payment || '',
    'deliveryDays': storageFilter?.deliveryDays || '',
  });

  const debouncedRouteSearchTerm = useDebounce(newRouteInputValue, 500);

  let { data: deliveryRouteData, isLoading: deliveryRouteLoading } = useGetDeliveryRoutesQuery({
    contains: { name: debouncedRouteSearchTerm },
    startTimeFrom: moment().startOf('day').valueOf(),
  }, { refetchOnMountOrArgChange: true, skip: !(showChangeRouteConfirmationModal || showFilterModal) });

  deliveryRouteData = deliveryRouteData?.data?.items?.map((item) => {
    return {
      ...item,
      label: `${item?.name} - ${item?.driver?.firstName}`,
    };
  }) || [];


  const debouncedTiffinSearchTerm = useDebounce(newTiffinInputValue, 500);

  let { data: tiffinData, isLoading: tiffinDataLoading } = useGetTiffinsQuery({
    contains: { name: debouncedTiffinSearchTerm },
  }, { refetchOnMountOrArgChange: true, skip: !showChangeTiffinConfirmationModal });

  const debouncedSearchTerm = useDebounce(searchText, 500);
  const customerFetchQuery = {
    page,
    pageSize,
    'contains': {
      'customer.firstName': debouncedSearchTerm,
      'customer.lastName': debouncedSearchTerm,
      'customer.address.description': debouncedSearchTerm,
      'customer.unit': debouncedSearchTerm,
      'customer.email': debouncedSearchTerm,
      'customer.phoneNumber.number': debouncedSearchTerm,
      'comment': debouncedSearchTerm,
      'deliveryInstructions': debouncedSearchTerm,
      'tiffin.name': debouncedSearchTerm,
    },
    'sortKey': '+customer.firstName|+customer.lastName|+customer.address.description',
    'status': filterObject?.status,
    'shipping': filterObject?.shipping,
    'startDate': filterObject?.startDate,
    'endDate': filterObject?.endDate,
    'route.id': filterObject?.['route'],
    'tiffin.id': filterObject?.['tiffin'],
    'itemsChanged': filterObject?.itemsChanged === showAllTiffinsText ? null :
      filterObject?.itemsChanged === showSpecialTiffinsText ? true :
        filterObject?.itemsChanged === showNonSpecialTiffinsText ? false : null,
    'paymentStatus': filterObject?.paymentStatus,
    'deliveryDays': filterObject?.deliveryDays,
  };
  const { data, isLoading, isSuccess, refetch } = useGetTiffinSubscribersQuery(customerFetchQuery, { refetchOnMountOrArgChange: true });
  const {
    data: customerStatsData,
    isLoading: customerStatsLoading,
  } = useGetTiffinSubscriberStatsQuery(customerFetchQuery, { refetchOnMountOrArgChange: true });
  const [trigger] = useLazyGetTiffinSubscribersQuery({});

  const [deleteCustomer, {
    data: deleteCustomerData,
    isSuccess: deleteCustomerIsSuccess,
    isError: deleteCustomerIsError,
    error: deleteCustomerError,
  }] = useDeleteTiffinSubscriberMutation();

  const [updateCustomer, {
    data: updateCustomerData,
    isSuccess: updateCustomerIsSuccess,
    isError: updateCustomerIsError,
    error: updateCustomerError,
  }] = useUpdateTiffinSubscriberMutation();

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setRows(data?.data?.items);
    }
  }, [isLoading, isSuccess, data?.data?.items]);

  useEffect(() => {
    if (!deliveryRouteLoading && !isLoading) {
      setIsDataReady(true);
    }
  }, [deliveryRouteLoading, isLoading]);

  const fetchAllPages = async (page = 1, pageSize = 200) => {
    const response = await trigger(customerFetchQuery);

    let data = response?.data?.data;
    const totalCount = data.totalCount;
    const totalPages = Math.ceil(totalCount / pageSize);

    if (page < totalPages) {
      const nextPageData = await fetchAllPages(page + 1, pageSize);
      data = { ...data, items: [...data.items, ...nextPageData.items] };
    }

    return data;
  };

  const selectAllOtherCustomers = async () => {
    setShowLoading(true);
    const allCustomers = await fetchAllPages();
    const allCustomerIds = allCustomers?.items?.map((item) => item?.id);
    setSelectedItems(allCustomerIds);
    setRows(allCustomers?.items);
    setShowLoading(false);
  };

  useEffect(() => {
    if (selectedItems.length && rows?.length && (selectedItems?.length === rows?.length || selectedItems?.length === data?.data?.totalCount)) {
      setSelectAllChecked(true);
    } else if (selectedItems.length && rows?.length && selectedItems?.length !== rows?.length) {
      setSelectAllChecked(false);
    }
  }, [selectedItems])

  useEffect(() => {
    if (!showLoading) {
      if (deleteCustomerIsSuccess && deleteCustomerData) {
        successSnackBar({ message: 'Customers deleted successfully' });
        setRows(rows.filter((row) => row?.id !== deleteCustomerData?.data?.id));
      } if (deleteCustomerIsError && deleteCustomerError) {
        errorSnackBar({ message: deleteCustomerError?.data?.errorMessage });
      }
    }
  }, [deleteCustomerIsSuccess, deleteCustomerIsError, deleteCustomerError]);

  useEffect(() => {
    if (!showLoading) {
      if (updateCustomerIsSuccess && updateCustomerData) {
        successSnackBar({ message: 'Customers updated successfully' });
        setRows(rows.map((row) => row?.id === updateCustomerData?.data?.id ? updateCustomerData?.data : row));
      } if (updateCustomerIsError && updateCustomerError) {
        errorSnackBar({ message: updateCustomerError?.data?.errorMessage });
      }
    }
  }, [updateCustomerIsSuccess, updateCustomerIsError, updateCustomerError]);

  const selectAllHandler = (e) => {
    if (e.target.checked) {
      setSelectedItems(rows?.map((row) => row?.id));
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
      setSelectedItems([]);
    }
  };

  const filterModal =
    <CustomerFilterModal
      open={showFilterModal}
      filterObject={filterObject}
      handleClose={() => setShowFilterModal(false)}
      setFilterCount={setFilterCount}
      setFilterObject={setFilterObject}
      setPage={setPage}
      data={{ routeData: [...deliveryRouteData, { id: 'null', label: 'None' }] }}
    />;

  const deleteConfirmationModal = <ConfirmationModal
    open={showDeleteConfirmationModal}
    handleClose={() => setShowDeleteConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{`Are you sure want to delete ${selectedItems?.length} customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Delete"
    actionHandler={async () => {
      setShowLoading(true);
      for (const item of selectedItems) {
        try {
          await deleteCustomer({ id: item });
        } catch (error) {
          // Handle error if needed
        }
      }

      setSelectedItems([]);
      setSelectAllChecked(false);
      setShowLoading(false);
      setShowDeleteConfirmationModal(false);
      refetch();
    }}
  />;

  const cancelConfirmationModal = <ConfirmationModal
    open={showCancelConfirmationModal}
    handleClose={() => setShowCancelConfirmationModal(false)}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>{`Are you sure want to cancel ${selectedItems?.length} customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Update"
    actionHandler={async () => {
      setShowCancelConfirmationModal(false);
      setShowLoading(true);

      for (const item of selectedItems) {
        try {
          const existingItem = rows?.find((row) => row?.id === item);
          await updateCustomer({ id: item, data: { ...existingItem, status: TIFFIN.STATUS.CANCEL } });
        } catch (error) {
          // Handle error if needed
        }
      }

      setSelectedItems([]);
      setSelectAllChecked(false);
      setShowLoading(false);
      setShowCancelConfirmationModal(false);
      refetch();
    }}
  />;

  const changeRouteConfirmationModal = <ConfirmationModal
    open={showChangeRouteConfirmationModal}
    handleClose={() => {
      setShowChangeRouteConfirmationModal(false)
    }}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Select new route</Typography>
      </Grid>
      <Grid item xs={12}>
        <TuiAutoComplete
          id="newRoute"
          name="newRoute"
          disabled={deliveryRouteLoading || !deliveryRouteData?.length}
          loading={deliveryRouteLoading}
          freeSolo
          onChange={(event, item) => {
            setNewRoute(item);
          }}
          inputValue={newRouteInputValue}
          onInputChange={(event, newInputValue) => {
            if (newInputValue) {
              const existingItem = deliveryRouteData?.find((item) => item?.label === newInputValue);
              setNewRouteInputValue(existingItem?.name);
            } else {
              setNewRouteInputValue('');
            }
          }}
          value={newRoute}
          label="New Route"
          labelKey='label'
          noOptionsText="No Item Found"
          helperText={!deliveryRouteData?.length ?
            <>
              <Typography
                variant="h7"
                fontWeight="bold"
                display="inline">
                No Upcoming Route Found
              </Typography>
              <CoreButton
                sx={{
                  p: 0,
                  m: 0,
                  color: 'secondary.contrastText',
                  textDecoration: 'underline'
                }}
                fullWidth={false}
                disableRipple
                onClickHandler={() => navigate('/routes/new')}
              >
                Add Route
              </CoreButton>
            </>
            : ''}
          options={deliveryRouteData || []}
        />
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">
          All selected customers will be updated with new route and delivery method as Home Delivery
        </Alert>
      </Grid>
    </Grid>}
    type="Update"
    actionHandler={async () => {
      setShowLoading(true);
      for (const item of selectedItems) {
        const existingItem = rows?.find((row) => row?.id === item);
        try {
          const result = await updateCustomer({
            id: item,
            data: {
              ...existingItem,
              shipping: DELIVERY_METHOD.HOME_DELIVERY,
              route: {
                ...existingItem?.route,
                id: newRoute?.id,
              }
            }
          });

          if (result?.error) {
            errorSnackBar({
              message: `Failed to update customer ${item?.customer?.firstName}`,
              autoHideDuration: 5000
            });
          }
        } catch (error) {
          errorSnackBar({
            message: `Failed to update customer ${item?.customer?.firstName}`,
            autoHideDuration: 5000
          });
        }
      }

      setSelectedItems([]);
      setSelectAllChecked(false);
      setShowLoading(false);
      setShowChangeRouteConfirmationModal(false);
      setNewRoute(null);
      setNewRouteInputValue('');
      refetch();
    }}
    actionHandlerDisabled={!newRoute}
  />;

  const changeTiffinConfirmationModal = <ConfirmationModal
    open={showChangeTiffinConfirmationModal}
    handleClose={() => {
      setShowChangeTiffinConfirmationModal(false)
    }}
    title={<Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Select new Tiffin</Typography>
      </Grid>
      <Grid item xs={12}>
        <TuiAutoComplete
          id="newTiffin"
          name="newTiffin"
          disabled={tiffinDataLoading || !tiffinData?.data?.items?.length}
          loading={tiffinDataLoading}
          freeSolo
          onChange={(event, item) => {
            setNewTiffin(item);
          }}
          inputValue={newTiffinInputValue}
          onInputChange={(event, newInputValue) => {
            if (newInputValue) {
              setNewTiffinInputValue(newInputValue);
            } else {
              setNewTiffinInputValue('');
            }
          }}
          value={newTiffin}
          label="New Tiffin"
          labelKey='name'
          noOptionsText="No Item Found"
          options={tiffinData?.data?.items || []}
        />
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info">
          It will only update name and items not price.
        </Alert>
      </Grid>
    </Grid>}
    type="Update"
    actionHandler={async () => {
      setShowLoading(true);
      for (const item of selectedItems) {
        const existingItem = rows?.find((row) => row?.id === item);
        try {
          const result = await updateCustomer({
            id: item,
            data: {
              ...existingItem,
              tiffin: {
                ...existingItem?.tiffin,
                id: newTiffin?.id,
                name: newTiffin?.name,
                items: newTiffin?.items,
              }
            }
          });

          if (result?.error) {
            errorSnackBar({
              message: `Failed to update customer ${item?.customer?.firstName}`,
              autoHideDuration: 5000
            });
          }
        } catch (error) {
          errorSnackBar({
            message: `Failed to update customer ${item?.customer?.firstName}`,
            autoHideDuration: 5000
          });
        }
      }

      setSelectedItems([]);
      setSelectAllChecked(false);
      setShowLoading(false);
      setShowChangeTiffinConfirmationModal(false);
      setNewTiffin(null);
      setNewTiffinInputValue('');
      refetch();
    }}
    actionHandlerDisabled={!newTiffin}
  />;

  const selectedItemsMenu = (
    <Grid container sx={{ mt: 1 }} justifyContent="flex-end" direction="row" spacing={1}>
      <Grid item>
        <CoreButton
          variant="contained"
          onClickHandler={() => setShowChangeTiffinConfirmationModal(true)}
        >
          Change Tiffin
        </CoreButton>
      </Grid>
      <Grid item>
        <CoreButton
          variant="contained"
          onClickHandler={() => setShowChangeRouteConfirmationModal(true)}
        >
          Change Route
        </CoreButton>
      </Grid>
      <Grid item>
        <CoreButton
          variant="contained"
          onClickHandler={() => setShowCancelConfirmationModal(true)}
        >
          Cancel Subscription
        </CoreButton>
      </Grid>
      <Grid item>
        <CoreButton
          variant="contained"
          onClickHandler={() => setShowDeleteConfirmationModal(true)}
        >
          Delete
        </CoreButton>
      </Grid>
      <Grid item>
        <Divider orientation="vertical" />
      </Grid>
      <Grid item>
        <CoreButton
          disableRipple
          fullWidth={false}
          variant="outlined"
          sx={{ whiteSpace: 'nowrap' }}
          onClickHandler={() => {
            setSelectedItems([]);
            setSelectAllChecked(false);
          }}
        >
          Clear Selection
        </CoreButton>
      </Grid>
    </Grid>
  );

  const menuItems = (
    <Grid container spacing={1} sx={{ mt: 0.5 }}>
      <Grid item>
        <Chip
          label={`Expired: ${customerStatsData?.data?.expiredCount}`}
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.EXPIRED)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.EXPIRED}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.EXPIRED}` : TIFFIN.STATUS.EXPIRED;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.EXPIRED) ? 'white' : '#E31A1C',
            borderColor: '#E31A1C',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.EXPIRED) ? '#E31A1C' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.EXPIRED) ? '#E31A1C !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.EXPIRED) ? 'white' : "#E31A1C",
            }
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.EXPIRING_SOON}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.EXPIRING_SOON}` : TIFFIN.STATUS.EXPIRING_SOON;

            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON) ? 'white' : '#EE8D22',
            borderColor: '#EE8D22',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON) ? '#EE8D22' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON) ? '#EE8D22 !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.EXPIRING_SOON) ? 'white' : "#EE8D22",
            }
          }}
          label={`Expiring: ${customerStatsData?.data?.expiringSoonCount}`}
        />
      </Grid>
      <Grid item>
        <Chip
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.HOLD)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.HOLD}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.HOLD}` : TIFFIN.STATUS.HOLD;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.HOLD) ? 'white' : '#008B8B',
            borderColor: '#008B8B',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.HOLD) ? '#008B8B' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.HOLD) ? '#008B8B !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.HOLD) ? 'white' : "#008B8B",
            }
          }}
          label={`Hold: ${customerStatsData?.data?.holdCount}`}
        />
      </Grid>
      <Grid item>
        <Chip
          label={`Active: ${customerStatsData?.data?.activeCount}`}
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.ACTIVE)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.ACTIVE}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.ACTIVE}` : TIFFIN.STATUS.ACTIVE;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.ACTIVE) ? 'white' : '#238443',
            borderColor: '#238443',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.ACTIVE) ? '#238443' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.ACTIVE) ? '#238443 !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.ACTIVE) ? 'white' : "#238443",
            }
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          label={`Not Started: ${customerStatsData?.data?.notStartedCount}`}
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.NOT_STARTED}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.NOT_STARTED}` : TIFFIN.STATUS.NOT_STARTED;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED) ? 'white' : '#2196f3',
            borderColor: '#2196f3',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED) ? '#2196f3' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED) ? '#2196f3 !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.NOT_STARTED) ? 'white' : "#2196f3",
            }
          }}
        />
      </Grid>
      <Grid item>
        <Chip
          label={`Cancelled: ${customerStatsData?.data?.cancelledCount}`}
          onClick={() => {
            let newStatus = '';
            if (filterObject?.status.includes(TIFFIN.STATUS.CANCEL)) {
              newStatus = filterObject?.status.replace(new RegExp(`(,\\s*)?${TIFFIN.STATUS.CANCEL}`), '').trim();
            } else {
              newStatus = filterObject?.status ? `${filterObject?.status},${TIFFIN.STATUS.CANCEL}` : TIFFIN.STATUS.CANCEL;
            }
            setFilterObject({ ...filterObject, status: newStatus });
            setPage(1);
            sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, status: newStatus }));
          }}
          variant='outlined'
          sx={{
            "& .MuiChip-label": {
              fontWeight: "bold"
            },
            color: filterObject?.status.includes(TIFFIN.STATUS.CANCEL) ? 'white' : '#818181',
            borderColor: '#818181',
            backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.CANCEL) ? '#818181' : 'transparent',
            "&:hover": {
              backgroundColor: filterObject?.status.includes(TIFFIN.STATUS.CANCEL) ? '#818181 !important' : null,
              color: filterObject?.status.includes(TIFFIN.STATUS.CANCEL) ? 'white' : "#818181",
            }
          }}
        />
      </Grid>
      <Grid item>
        <Divider orientation="vertical" />
      </Grid>
    </Grid>
  );

  if (!isDataReady || showLoading) {
    return <TuiSpinner />
  }

  const pageCount = Math.ceil(data?.data?.totalCount / pageSize);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <TuiAppBar
            buttonDisabled={!roles?.tiffins?.create}
            buttonClickHandler={() => {
              navigate('new');
            }}
            setSearchText={setSearchText}
            searchText={searchText?.toString()}
            searchHelperText='Name/Address/Tiffin'
            selectedItems={selectedItems}
            menuItems={menuItems}
            selectedItemsMenu={selectedItemsMenu}
            filter={{ filterModal, showFilterModal, setShowFilterModal, filterCount }}
            title={<Box display="flex" alignItems="center">
              <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
                Customers
              </Typography>
              <IconButton
                onClick={() => refetch()}
                aria-label="refresh"
                size="medium">
                <RefreshIcon fontSize="inherit" style={{ color: '#FF4444' }} />
              </IconButton>
            </Box>}
          />
        </Grid>
        <Grid container item xs={12} sx={{ pt: 2 }} spacing={1}>
          {selectedItems?.length > 0 && pageCount > 1 && <Grid item xs={12} container sx={{ backgroundColor: 'lightyellow', ml: 1, borderRadius: 2 }}>
            <Grid item xs='auto'>
              <Typography variant="body1" sx={{ color: 'grey' }}>
                {
                  selectedItems?.length === data?.data?.totalCount ?
                    `${data?.data?.totalCount} customers have been selected.`
                    :
                    `${selectedItems?.length} customers on this page have been selected.`
                }
              </Typography>
            </Grid>
            {/* <Grid item xs="auto" sx={{ ml: 1 }}>
              {selectedItems?.length !== data?.data?.totalCount && <CoreButton
                aria-label="close"
                color="inherit"
                size="small"
                disableRipple
                sx={{
                  color: 'secondary.contrastText',
                  textDecoration: 'underline',
                }}
                isLoading={showLoading}
                onClickHandler={() => {
                  // fetch subscriber recursively and push ids to selectedItems
                  selectAllOtherCustomers()
                }}
              >
                {`Select all ${data?.data?.totalCount} customers`}
              </CoreButton>}
            </Grid> */}
          </Grid>}
          <Grid item xs={12}>
            <CustomerListComponent
              isLoading={isLoading}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              selectAllHandler={selectAllHandler}
              selectAllChecked={selectAllChecked}
              rows={rows}
              setRows={setRows}
              page={page}
              setPage={setPage}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <Grid item xs={6} container justifyContent='flex-end' sx={{ pb: 1 }}>
          <Grid item xs={6}>
            {data?.data?.routeMissingWarningCount > 0 &&
              <Alert
                severity='warning'
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      if (filterObject?.shipping !== DELIVERY_METHOD.HOME_DELIVERY &&
                        filterObject?.route !== 'null') {
                        setFilterObject({ ...filterObject, route: 'null', shipping: DELIVERY_METHOD.HOME_DELIVERY });
                        sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, route: 'null' }));
                        setFilterCount(filterCount + 2);
                      } else {
                        setFilterObject({ ...filterObject, route: '', shipping: '' });
                        sessionStorage.setItem(STORAGE_KEYS.CUSTOMER_FILTER, JSON.stringify({ ...filterObject, route: '' }));
                        setFilterCount(filterCount - 2);
                      }
                      setPage(1);
                    }}
                  >
                    {
                      filterObject?.shipping !== DELIVERY_METHOD.HOME_DELIVERY &&
                        filterObject?.route !== 'null' ? 'APPLY FILTER' : 'CLEAR FILTER'
                    }
                  </Button>
                }>
                {data?.data?.routeMissingWarningCount} customers are missing route.
              </Alert>
            }
          </Grid>
        </Grid>
        {selectedItems?.length <= 0 &&
          <Grid item xs={6} container justifyContent='flex-end'>
            <BasePagination
              data={data}
              pageSize={pageSize}
              page={page - 1}
              pageSizeChangeHandler={(event) => {
                setPageSize(parseInt(event.target.value));
                setPage(1);
              }}
              pageChangeHandler={(event, val) => {
                setPage(val + 1);
              }}
            />
          </Grid>}
      </Grid>
      {deleteConfirmationModal}
      {cancelConfirmationModal}
      {changeRouteConfirmationModal}
      {changeTiffinConfirmationModal}
    </>
  );
}
