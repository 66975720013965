import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid, Typography, Link, InputAdornment, IconButton } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { CoreButton, BaseTextField, PublicContainer, Hooks } from '../../../core';
import { useLoginMutation } from '../../../js/slices/api_slices/auth_api_slice';
import { USERS } from '../../../js/lib/constants';

const { useSnackBarNotification } = Hooks;

const LoginComponent = function () {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { errorSnackBar } = useSnackBarNotification();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [loginAction, {
    data,
    isSuccess,
    isLoading,
    error,
    isError,
  }] = useLoginMutation();

  useEffect(() => {
    if (isSuccess && data) {
      if (data?.data?.type === USERS.TYPES.EMPLOYEE.PACKER) {
        navigate('/packing', { replace: true });
      } else if (data?.data?.type === USERS.TYPES.EMPLOYEE.COOK) {
        navigate('/cooking-plan', { replace: true });
      } else {
        navigate('/tiffins', { replace: true });
      }
    } else if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
  }, [data, isSuccess, dispatch, isError, error]);

  const component =
    <Grid container justifyContent='center'>
      <Grid
        item
        component='form'
        container
        sx={{ maxWidth: 400, p: 1 }}
        spacing={2}
        onSubmit={handleSubmit(loginAction)}
      >
        <Grid item xs={12} container justifyContent='center'>
          <Typography variant="h5" fontWeight="bold" >
            Login
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <Typography mb={4} color="secondary.contrastText.main">
            Login to continue
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BaseTextField
            id="email"
            name="email"
            label="Email Address"
            errors={errors}
            validate={register('email', {
              required: 'Email is required',
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: 'Email must be valid',
              },
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <BaseTextField
            id="password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff sx={{ color: 'secondary.contrastText' }} /> : <Visibility sx={{ color: 'secondary.contrastText' }} />}
                </IconButton>
              </InputAdornment>,
            }}
            errors={errors}
            validate={register('password', {
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be greater then 6 characters',
              },
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <CoreButton
            type='submit'
            variant='contained'
            isLoading={isLoading}
            color="primary"
          >
            Login
          </CoreButton>
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={{ pt: 1 }}
        >
          <Grid item xs={8} container justifyContent='flex-start'>
            <Typography fontSize={15}>
              <Link href='/signup'>Create New Account</Link>
            </Typography>
          </Grid>
          <Grid item xs={4} container justifyContent='flex-end'>
            <Typography sx={{ color: 'secondary.contrastText' }} fontSize={15}>
              <Link style={{ color: 'inherit' }} href='/reset-password' underline='none'>Forgot Password</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>;

  return (
    <PublicContainer component={component} />
  );
};

export default LoginComponent;
