import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import { TuiAppBar, TuiSpinner, Hooks } from '../../../core';
import TiffinSettingsForm from '../components/settings_tiffin';
import { settingsApi } from '../../../js/slices/api_slices';
import { TIMEZONES } from '../../../js/lib/constants';
import Footer from '../components/footer';

const { useUpdateSettingsMutation, useGetSettingsQuery } = settingsApi;

export default function SettingsTiffinView() {
  const [updateSettings, { isLoading, isSuccess, isError, error, data }] = useUpdateSettingsMutation();
  const { isLoading: getSettingsLoading, data: settings } = useGetSettingsQuery({}, { refetchOnMountOrArgChange: true });
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const methods = useForm({
    defaultValues: {
      settings: {
        ...settings?.data,
        timezone: settings?.data?.timezone ? Object.entries(TIMEZONES).find(([key, value]) => value === settings?.data?.timezone)?.[0] : null,
      },
    },
  });

  useEffect(() => {
    methods.reset({
      settings: {
        ...settings?.data,
        timezone: settings?.data?.timezone ? Object.entries(TIMEZONES).find(([key, value]) => value === settings?.data?.timezone)?.[0] : null,
      },
    });
  }, [settings?.data]);

  useEffect(() => {
    if (isSuccess && data) {
      methods.reset({
        settings: {
          ...data?.data,
          timezone: data?.data?.timezone ? Object.entries(TIMEZONES).find(([key, value]) => value === data?.data?.timezone)?.[0] : null,
        },
      });
      successSnackBar({ message: 'Settings Successfully Updated' });
    }
    if (isError) {
      errorSnackBar({ message: error?.data?.errorMessage || 'Settings Failed to  Update' });
    }
  }, [isSuccess, isError, data]);

  if (isLoading || getSettingsLoading) {
    return <TuiSpinner />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <TuiAppBar
          title="Settings" />
      </Grid>
      <FormProvider {...methods}>
        <Grid
          item
          xs={12}
          sx={{ pt: 2, pl: 1 }}
          container>
          <TiffinSettingsForm />
        </Grid>
      </FormProvider>
      <Footer
        buttonText='Update'
        buttonDisabled={isLoading || !methods?.formState?.isDirty}
        buttonClickHandler={() => {
          const allValues = methods.getValues();
          updateSettings(allValues?.settings);
        }}
      />
    </Grid>
  );
}
