import React from 'react';
import { TablePagination, Stack } from '@mui/material';

export default function BasePagination({ data, pageSize, page, pageChangeHandler, pageSizeChangeHandler }) {
  return <TablePagination
    count={data?.data?.totalCount}
    page={page}
    rowsPerPage={pageSize}
    onRowsPerPageChange={pageSizeChangeHandler}
    onPageChange={pageChangeHandler}>
  </TablePagination>;
}
