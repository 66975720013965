import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import {
  Modal,
  Typography,
  Grid,
  Chip,
  Divider,
} from '@mui/material';

import { CoreButton, ToggleDays, Hooks, TuiSpinner } from '../../../core';
import { capitalizeFirstLetter, getFormattedDateFromUnixTimestamp, getFormattedDateTimeFromUnixTimestamp } from '../../../js/lib/utils';
import { tiffinsApi, shippingStatusApi } from '../../../js/slices/api_slices';
import { DELIVERY_METHOD, TIFFIN } from '../../../js/lib/constants';
import { getStatusChip, getRemainingDays, getStatusLabel } from '../customer_utils';

const { useUpdateTiffinSubscriberMutation, useGetTiffinSubscriberWithRouteAndDriverQuery } = tiffinsApi;
const { useSnackBarNotification } = Hooks;
const { useUpdateShippingStatusMutation, useGetShippingStatusQuery } = shippingStatusApi;

const getShippingStatusChip = (item, status, shippingStatusError) => {
  switch (status) {
    case TIFFIN.SHIPPING_STATUS.PENDING:
      return <Chip label='Pending' color="warning" />;
    case TIFFIN.SHIPPING_STATUS.DELIVERED:
    case TIFFIN.SHIPPING_STATUS.PICKED_UP:
      return <Chip label={status} color="success" />;
    case TIFFIN.SHIPPING_STATUS.NONE:
      return <Chip label='None' />;
    default:
      if (item?.status === TIFFIN.STATUS.EXPIRED || item?.status === TIFFIN.STATUS.NOT_STARTED) {
        return <Chip label='None' />;
      }
      if (shippingStatusError?.status === 404) {
        return <Chip label='Pending' color="warning" />;
      }
      return <Chip label='None' />;
  }
};

export default function CustomerViewModal({ open, handleClose, item }) {
  const [action, setAction] = useState('');
  const [shippingStatus, setShippingStatus] = useState(null);
  const settings = useSelector((store) => store?.settings?.settings);
  const remainingDays = getRemainingDays(item, settings?.timezone);
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();

  let { data: subscriberData, isLoading: subscriberDataLoading } = useGetTiffinSubscriberWithRouteAndDriverQuery({
    id: item?.id,
  }, { skip: !(item?.id && open) });

  subscriberData = subscriberData?.data;

  const { handleSubmit, control, formState: { errors }, watch, reset, register, setValue } = useForm({
    defaultValues: {
      ...item,
      holdHistory: {
        startDate: item?.holdHistory?.startDate ? moment(item?.holdHistory?.startDate) : null,
        endDate: item?.holdHistory?.endDate ? moment(item?.holdHistory?.endDate) : null,
      },
      tiffin: {
        plan: {
          ...item?.tiffin.plan,
          count: 1,
        },
      },
      startDate: moment().add(1, 'days'),
    },
  });

  useEffect(() => {
    reset(item);
  }, [item]);

  useEffect(() => {
    register('recordPaymentStartDate', { value: moment(item?.endDate).add(1, 'days') });
  }, []);

  useEffect(() => {
    const planType = watch('tiffin.plan.type');
    const startDateWatcher = action === 'RecordPayment' ? watch('recordPaymentStartDate') : watch('startDate');
    if (startDateWatcher) {
      if (planType === TIFFIN.PLANS.SINGLE) {
        // TODO need to add delivery days check
        setValue('endDate', moment(startDateWatcher).add(1, 'days'));
      } else if (planType === TIFFIN.PLANS.WEEKLY) {
        setValue('endDate', moment(startDateWatcher).add(1, 'weeks'));
      } else if (planType === TIFFIN.PLANS.MONTHLY) {
        setValue('endDate', moment(startDateWatcher).add(1, 'months'));
      }
    }
  }, [watch('tiffin.plan.type'), action, watch('startDate'), watch('recordPaymentStartDate')]);

  const [updateShippingStatus, {
    data: updateShippingStatusFromApi,
    isSuccess: updateShippingStatusSuccess,
    error: updateShippingStatusError,
    isError: updateShippingStatusIsError,
  }] = useUpdateShippingStatusMutation();

  const { data: shippingStatusFromApi, isLoading: itemLoading, error: shippingStatusError, isSuccess: shippingStatusIsSuccess } = useGetShippingStatusQuery({
    tiffinSubscriberId: item?.id,
    startDate: moment().startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
  }, { refetchOnMountOrArgChange: true, skip: item && item.shipping === DELIVERY_METHOD.PICK_UP ? false : true });

  useEffect(() => {
    if (shippingStatusIsSuccess) {
      setShippingStatus(shippingStatusFromApi?.data?.status);
    } else if (shippingStatusError?.status === 404) {
      setShippingStatus(TIFFIN.SHIPPING_STATUS.PENDING);
    }
  }, [shippingStatusIsSuccess, shippingStatusFromApi, shippingStatusError]);

  useEffect(() => {
    if (updateShippingStatusSuccess) {
      setShippingStatus(updateShippingStatusFromApi?.data?.status);
      successSnackBar({ message: 'Status udpated successfully' });
    } if (updateShippingStatusIsError && updateShippingStatusError) {
      errorSnackBar({ message: updateShippingStatusError?.data?.errorMessage });
    }
  }, [updateShippingStatusSuccess, updateShippingStatusIsError, updateShippingStatusError, updateShippingStatusFromApi]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 2,
  };

  const [updateTiffinSubscriber, {
    isSuccess,
    isError,
    error,
    isLoading,
  }] = useUpdateTiffinSubscriberMutation();

  useEffect(() => {
    if (isSuccess) {
      successSnackBar({ message: 'Customer updated successfully' });
      setAction('');
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
      setAction('');
    }
  }, [isSuccess, isError, error]);

  if (isLoading || itemLoading || subscriberDataLoading) {
    return <TuiSpinner />;
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setAction('');
      }}
    >
      <Grid
        container
        spacing={1}
        component='form'
        onSubmit={handleSubmit((userInput) => {
          userInput.startDate = moment(userInput.startDate).startOf('day').valueOf();
          userInput.endDate = moment(userInput.endDate).endOf('day').valueOf();
          updateTiffinSubscriber({ id: item?.id, data: userInput });
          handleClose();
        })}
        sx={style}>
        <Grid item xs={12} container>
          <Grid item xs={6} container spacing={1}>
            <Grid item xs="auto">
              <Typography sx={{ color: 'secondary' }} fontWeight='bold' fontSize={19}>{item?.customer?.firstName} {item?.customer?.lastName}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={6} container justifyContent='flex-end'>
            <Grid item xs="auto">
              {getStatusChip(item)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {!action && <Grid item xs={12} container spacing={3}>
          <Grid item xs={12} container>
            <Grid xs={6} item>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Tiffin</Typography>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={8}>
                  <Typography sx={{ color: 'secondary' }}>{capitalizeFirstLetter(item?.tiffin?.name)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6} item>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Price</Typography>
              </Grid>
              <Grid item xs={12} container>
                <Grid item xs={8}>
                  <Typography sx={{ color: 'secondary' }}>$ {item?.tiffin?.plan?.price}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={6} container>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Start Date</Typography>
              </Grid>
              <Grid item xs={12} container>
                <Typography sx={{ color: 'secondary' }}>{getFormattedDateFromUnixTimestamp(item?.startDate)}</Typography>
                {item?.status === TIFFIN.STATUS.NOT_STARTED && <Chip
                  sx={{ ml: 1 }}
                  size="small"
                  color={item?.startDate > Date.now() ? 'info' :
                    remainingDays > 6 ?
                      'success' :
                      remainingDays <= 5 && remainingDays >= 0 ? 'warning' : 'error'}
                  label={getStatusLabel(item, settings?.timezone)} />}
              </Grid>
            </Grid>
            <Grid item xs={6} container>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>End Date</Typography>
              </Grid>
              <Grid item xs={12} container>
                <Typography sx={{ color: 'secondary' }}>{getFormattedDateFromUnixTimestamp(item?.endDate)}</Typography>
                {item?.status !== TIFFIN.STATUS.NOT_STARTED && <Chip
                  sx={{ ml: 1 }}
                  size="small"
                  color={item?.startDate > Date.now() ? 'info' :
                    remainingDays > 6 ?
                      'success' :
                      remainingDays <= 5 && remainingDays >= 0 ? 'warning' : 'error'}
                  label={getStatusLabel(item, settings?.timezone)} />}
              </Grid>
            </Grid>
          </Grid>
          {item?.holdStartDate &&
            item?.holdEndDate &&
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={6}>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Phone Number</Typography>
              </Grid>
              <Grid item container xs={6} >
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Hold Period</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ color: 'secondary' }}>{item?.customer?.phoneNumber?.number}</Typography>
              </Grid>
              <Grid item xs={6} container>
                <Typography sx={{ color: 'secondary' }}>{`${getFormattedDateFromUnixTimestamp(item?.holdStartDate)} - ${getFormattedDateFromUnixTimestamp(item?.holdEndDate)}`}</Typography>
              </Grid>
            </Grid>}
          <Grid item xs={12} container>
            <Grid item xs={2}>
              <Grid item xs={12} container>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Unit</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary' }}>{item?.customer?.unit || '--'} </Typography>
              </Grid>
            </Grid>
            <Grid item xs={10} container>
              <Grid item xs={12} container>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Address</Typography>
                <Chip size="small" label={item?.shipping} sx={{ ml: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary' }}>{item?.customer?.address?.description} </Typography>
              </Grid>
            </Grid>
          </Grid>
          {item?.shipping === DELIVERY_METHOD.HOME_DELIVERY && <Grid item xs={12} container direction='row'>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Route</Typography>
              </Grid>
              <Grid item container xs={6} >
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Driver</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography sx={{ color: 'secondary' }}>{subscriberData?.route?.name || ''}</Typography>
              </Grid>
              <Grid item container xs={6}>
                <Typography sx={{ color: 'secondary' }}>{subscriberData?.route?.driver?.firstName || ''} {subscriberData?.route?.driver?.lastName || ''}</Typography>
              </Grid>
            </Grid>
          </Grid>}
          <Grid item xs={12} container>
            <Grid item xs={6} container>
              <Grid item xs={12} container>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Delivery Instructions</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary' }}>{item?.deliveryInstructions || '--'} </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12} container>
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Comments</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ color: 'secondary' }}>{item?.comment || '--'} </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={12}>
              <Typography sx={{ color: 'secondary' }} fontWeight='bold'>{item?.shipping} Days</Typography>
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <ToggleDays existingDays={item?.deliveryDays} disabled={true} />
              </Grid>
            </Grid>
          </Grid>
          {item?.shipping === DELIVERY_METHOD.PICK_UP && <Grid item xs={12} container>
            <Grid item xs={12} container>
              <Grid item container xs={6} >
                <Typography sx={{ color: 'secondary' }} fontWeight='bold'>Pickup Status</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container>
              <Grid item container xs={6}>
                <Typography sx={{ color: 'secondary' }}>{getShippingStatusChip(item, shippingStatus, shippingStatusError)}</Typography>
              </Grid>
              <Grid item xs={4} container justifyContent='flex-end'>
                {item?.shipping === DELIVERY_METHOD.PICK_UP &&
                  (item?.status === TIFFIN.STATUS.ACTIVE || item?.status === TIFFIN.STATUS.EXPIRING_SOON) &&
                  !action &&
                  <>
                    {(shippingStatus === TIFFIN.SHIPPING_STATUS.PENDING) && <CoreButton
                      fullWidth={true}
                      variant='contained'
                      onClickHandler={() => {
                        updateShippingStatus({
                          tiffinSubscriberId: item?.id,
                          data: {
                            startDate: moment().startOf('day').valueOf(),
                            endDate: moment().endOf('day').valueOf(),
                            status: TIFFIN.SHIPPING_STATUS.PICKED_UP,
                          },
                        });
                        handleClose();
                      }}
                    >
                      Confirm Pick Up
                    </CoreButton>}
                    {shippingStatus === TIFFIN.SHIPPING_STATUS.PICKED_UP && <CoreButton
                      fullWidth={false}
                      variant='contained'
                      onClickHandler={() => {
                        updateShippingStatus({
                          tiffinSubscriberId: item?.id,
                          data: {
                            from: moment().startOf('day').valueOf(),
                            to: moment().endOf('day').valueOf(),
                            status: TIFFIN.SHIPPING_STATUS.PENDING,
                          },
                        });
                        handleClose();
                      }}
                    >
                      Cancel Pick Up
                    </CoreButton>}
                  </>}
              </Grid>
            </Grid>
          </Grid>}
          <Grid item xs={12} direction='column' container>
            <Grid item>
              <Typography sx={{ color: 'grey.500', fontSize: '0.75rem' }}>
                Created at: {item?.createdAt ? getFormattedDateTimeFromUnixTimestamp(item?.createdAt) : '--'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'grey.500', fontSize: '0.75rem' }}>
                Last modified at: {item?.modifiedAt ? getFormattedDateTimeFromUnixTimestamp(item?.modifiedAt) : '--'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>}
      </Grid>
    </Modal >
  );
}
