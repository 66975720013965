import {createSlice} from '@reduxjs/toolkit';
import settingsApi from '../api_slices/settings_api_slice';
import { STORAGE_KEYS, STYLING } from '../../lib/constants';

const initialState = {
  settings: {},
};

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      settingsApi.endpoints.getSettings.matchFulfilled,
      (state, {payload}) => {
        const {data} = payload;
        if (data?.styling?.fontSize) {
          localStorage.setItem(STORAGE_KEYS.FONT_SIZE, data.styling.fontSize);
        }
        state.settings = data;
      },
    ),
    builder.addMatcher(
      settingsApi.endpoints.updateSettings.matchFulfilled,
      (state, {payload}) => {
        const {data} = payload;
        state.settings = data;
      },
    );
  },
});

const {reducer} = settingsSlice;

export default reducer;
