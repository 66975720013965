import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Divider,
  Drawer,
  Grid,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  DataGrid,
} from '@mui/x-data-grid';

import { DatePicker, CoreButton, Hooks, TuiSpinner } from '../../../core';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { TIFFIN } from '../../../js/lib/constants';
import { getFormattedDateFromUnixTimestamp } from '../../../js/lib/utils';

const { useSnackBarNotification } = Hooks;
const { useUpdateTiffinSubscriberMutation } = tiffinsApi;

export default function CustomerHoldDrawer({ open, handleClose, viewOnly, item, fetchCustomer }) {
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [rows, setRows] = useState(item?.holdHistory || []);
  const [action, setAction] = useState(null);

  const { handleSubmit, formState: { errors }, control, watch, setValue } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    const history = item?.holdHistory || [];
    const currentHold = item?.holdEndDate && item?.holdStartDate ? [{
      id: item?.id,
      startDate: item?.holdStartDate,
      endDate: item?.holdEndDate,
    }] : [];

    setRows([...currentHold, ...history]);
  }, [item]);

  const [updateCustomer, {
    data: updateCustomerData,
    isSuccess: updateCustomerSuccess,
    error: updateCustomerError,
    isError: updateCustomerIsError,
    isLoading: updateCustomerIsLoading,
  }] = useUpdateTiffinSubscriberMutation();

  useEffect(() => {
    if (updateCustomerSuccess && updateCustomerData) {
      successSnackBar({ message: 'Customer updated successfully' });
      setValue('holdStartDate', null);
      setValue('holdEndDate', null);
      const customerData = updateCustomerData?.data;
      if (action === 'update') {
        setRows([{
          id: customerData?.id,
          startDate: customerData?.holdStartDate,
          endDate: customerData?.holdEndDate,
        }, ...rows]);
      }
      if (action === 'delete') {
        setRows(rows.filter(row => row.id !== customerData?.id));
      }
      fetchCustomer({ id: customerData?.id });
      setAction(null);
    } if (updateCustomerIsError && updateCustomerError) {
      errorSnackBar({ message: updateCustomerError?.data?.errorMessage });
    }
  }, [updateCustomerSuccess, updateCustomerIsError, updateCustomerData, updateCustomerError]);

  const columns = [
    { field: 'startDate', headerName: 'Start Date', width: 120, editable: false },
    { field: 'endDate', headerName: 'End Date', width: 120, editable: false },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Actions',
      width: 130,
      cellClassName: 'actions',
      getActions: (data) => {
        const result = [];
        if (moment().endOf('day').isBefore(data?.row?.endDate)) {
          result.push(
            updateCustomerIsLoading ? <TuiSpinner /> : <IconButton
              key={item?.id}
              edge="end"
              aria-label="delete"
              onClick={() => {
                setAction('delete');
                updateCustomer({
                  id: item?.id,
                  data: {
                    ...item,
                    holdEndDate: null,
                    holdStartDate: null,
                  }
                });
              }}>
              <DeleteOutlineIcon />
            </IconButton>
          );
        }
        return result;
      },
    },
  ];

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: { width: 450 },
      }}
      open={open}
      onClose={handleClose}>
      <Grid container sx={{ p: 2 }} spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <IconButton
              onClick={handleClose}
              sx={{
                p: 0,
                mb: 2,
              }} >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" fontWeight='bold'>{viewOnly ? 'View Hold Date History' : 'Add Hold Date'}</Typography>
            <Typography color="secondary.contrastText.main">List of the hold dates
            </Typography>
          </Grid>
        </Grid>
        {item?.status !== TIFFIN.STATUS.CANCEL && item?.status !== TIFFIN.STATUS.EXPIRED && <Grid
          item
          xs={12}
          container
          spacing={1}
          sx={{ mt: 1 }}
          component="form"
          onSubmit={handleSubmit((userInput) => {
            setAction('update');
            updateCustomer({
              id: item?.id,
              data: {
                ...item,
                holdStartDate: moment(userInput?.holdStartDate).startOf('day').valueOf(),
                holdEndDate: moment(userInput?.holdEndDate).endOf('day').valueOf(),
              }
            });
          })}
        >
          <Grid item xs={5} sx={{ height: '10px' }}>
            <Controller
              control={control}
              rules={{ required: 'Invalid Start Date' }}
              name="holdStartDate"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  size="small"
                  required={true}
                  control={control}
                  disablePast={true}
                  disabled={item?.holdStartDate}
                  onChange={(event) => {
                    onChange(event);
                  }}
                  errors={errors}
                  value={value && moment(value).isValid() ? moment(value) : null}
                  minDate={moment().subtract(1, 'days')}
                  label="Start Date"
                />
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <Controller
              control={control}
              rules={{
                required: 'Invalid End Date',
                validate: value => {
                  return moment(value).isSameOrAfter(moment(watch('holdStartDate'))) || 'End Date must be after Start Date';
                }
              }}
              name="holdEndDate"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  name="holdEndDate"
                  required={true}
                  control={control}
                  disablePast={true}
                  disabled={item?.holdEndDate}
                  onChange={(event) => {
                    onChange(event);
                  }}
                  errors={errors}
                  value={value ? moment(value) : null}
                  label="End Date"
                />
              )}
            />
          </Grid>
          <Grid item xs={2} container justifyContent='flex-end'>
            {!viewOnly && <CoreButton
              disabled={!watch('holdStartDate') || !watch('holdEndDate')}
              variant='contained'
              isLoading={updateCustomerIsLoading}
              fullWidth={false}
              sx={{
                'maxHeight': 40,
                'backgroundColor': 'secondary.contrastText',
              }}
              type="submit"
            >Add
            </CoreButton>}
          </Grid>
        </Grid>}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {rows?.length > 0 && <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography fontWeight="bold" variant="h6">Items</Typography>
          </Grid>
        </Grid>}
        <Grid item xs={12}>
          <Box
            sx={{
              'height': 370,
              'width': '100%',
              '& .actions': {
                color: 'text.secondary',
              },
              '& .textPrimary': {
                color: 'text.primary',
              },
            }}
          >
            <DataGrid
              rows={rows.map((row) => {
                return {
                  ...row,
                  startDate: getFormattedDateFromUnixTimestamp(row?.startDate),
                  endDate: getFormattedDateFromUnixTimestamp(row?.endDate),
                }
              })}
              columns={columns}
              editMode="row"
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Drawer>
  );
}

