import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate, useResolvedPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Typography
} from '@mui/material';

import HomePageDrawerNav from './homepage_drawer';
import { CoreButton } from '../../../core';
import { businessApi, rolesApi, settingsApi, authApi } from '../../../js/slices/api_slices';
import SettingsUpdateImage from '../../../assets/images/settings_update.svg';

const { useGetBusinessQuery } = businessApi;
const { useGetRolesQuery } = rolesApi;
const { useGetSettingsQuery } = settingsApi;
const { useRefreshTokenMutation } = authApi;

function HomePageComponent() {
  const navigate = useNavigate();
  const selectedRouteParams = useResolvedPath({})?.pathname;
  const { isLoading: getSettingsLoading } = useGetSettingsQuery({}, { refetchOnMountOrArgChange: true });
  const { isLoading: getBusinessLoading } = useGetBusinessQuery({}, { refetchOnMountOrArgChange: true });
  const { isLoading } = useGetRolesQuery({}, { refetchOnMountOrArgChange: true });
  const settings = useSelector((store) => store?.settings?.settings);

  const [fetchRefreshToken, { isLoading: refreshTokenIsLoading }] = useRefreshTokenMutation();

  useLayoutEffect(() => {
    fetchRefreshToken();
  }, []);

  const shouldBeOnSettingsPage = useResolvedPath({}).pathname !== '/settings/business' && !settings?.timezone;
  useEffect(() => {
    if (selectedRouteParams && selectedRouteParams === '/') {
      navigate('/customers');
    }
  }, [selectedRouteParams]);

  return (
    <Grid
      container
      sx={{
        p: 1,
      }}
    >
      {!isLoading && !getSettingsLoading && !getBusinessLoading && !refreshTokenIsLoading && <>
        <Grid item>
          <HomePageDrawerNav />
        </Grid>
        <Grid item sx={{ height: '98vh', display: 'flex', flexDirection: 'column', flex: 1 }}>
          {!isLoading && !shouldBeOnSettingsPage && <Outlet />}
          {!isLoading && shouldBeOnSettingsPage &&
            <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
              <Grid item>
                <img src={SettingsUpdateImage} alt="coming-soon" width="300" height="300" />
              </Grid>
              <Grid item>
                <Typography variant='h5' fontWeight='bold'>Settings Update Required</Typography>
              </Grid>
              <Grid item>
                <Typography fontSize={15} color="secondary.contrastText.main">
                  Please update business settings to proceed
                </Typography>
              </Grid>
              <CoreButton
                variant='contained'
                fullWidth={false}
                onClickHandler={() => {
                  navigate('/settings/business', { replace: true });
                }}
                sx={{
                  'width': '200px',
                  'fontSize': '15',
                  'mt': 2,
                  'backgroundColor': 'secondary.contrastText',
                  ':hover': {
                    backgroundColor: 'secondary.contrastText',
                  },
                }}
              >Go To Settings
              </CoreButton>
            </Grid>
          }
        </Grid>
      </>}
    </Grid>
  );
}

export default HomePageComponent;
