import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {useLogoutMutation} from '../../../js/slices/api_slices/auth_api_slice';
import {CoreButton, BaseModal} from '../../../core';
import {logout} from '../../../js/slices/reducer_slices/user_reducer_slice';
import useSnackBarNotification from '../../../core/hooks/use_snack_bar_notification';

export default function LogoutComponent({displayModal, setDisplayModal}) {
  const {errorSnackBar} = useSnackBarNotification();
  const dispatch = useDispatch();

  const [logoutAction, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useLogoutMutation();
  const navigate = useNavigate();

  const logoutActionButtons = [
    <CoreButton
      key="logoutAction"
      isLoading={isLoading}
      onClickHandler={() => {
        logoutAction();
      }}
      sx={{
        'backgroundColor': 'white',
        'mb': 2,
        'color': 'primary.main',
        ':hover': {
          backgroundColor: 'secondary.main',
        },
      }}
    >
      Logout
    </CoreButton>,
  ];

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(logout());
      navigate('/login', {replace: true});
      setDisplayModal(false);
    }
    if (error && isError) {
      errorSnackBar({message: error?.data?.errorMessage});
      setDisplayModal(false);
    }
  }, [isSuccess, data, error, isError, isLoading]);

  return (
    <BaseModal
      open={displayModal}
      handleClose={() => setDisplayModal(false)}
      actionText="Are you sure you want to logout ?"
      actionButtons={logoutActionButtons}
    />
  );
}
