import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Alert, Grid, Tooltip } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RouteForm from '../components/route_form';
import { deliveryRoutesApi } from '../../../js/slices/api_slices';
import { TIFFIN } from '../../../js/lib/constants';

import { TuiAppBar, Hooks, TuiSpinner, CoreButton } from '../../../core/index';

const { useGetDeliveryRouteQuery, useUpdateDeliveryRouteMutation } = deliveryRoutesApi;
import RouteAreaDrawer from '../components/route_area_drawer';

export default function EditRouteView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const [showRouteAreaDrawer, setShowRouteAreaDrawer] = useState(false);
  const settings = useSelector((store) => store?.settings?.settings);

  const { data: deliveryRouteData, isLoading: deliveryRouteLoading } = useGetDeliveryRouteQuery({
    id,
  }, { refetchOnMountOrArgChange: true, skip: !id });

  const startTime = deliveryRouteData?.data?.startTime;
  const startTimeMoment = startTime ? moment(startTime) : moment().set({ h: 17, m: 0, s: 0 });

  const methods = useForm({
    defaultValues: {
      ...deliveryRouteData?.data,
      startTime: startTimeMoment,
      startAddress: null,
      finishAddress: null,
      driver: null,
      type: null,
      deliveryArea: {
        ...deliveryRouteData?.data?.deliveryArea,
        type: null,
        coordinates: [],
      }
    },
  });
  const { reset, handleSubmit, watch, formState: { isSubmitSuccessful } } = methods;
  const viewOnly = watch('status') === TIFFIN.DELIVERY_ROUTES_STATUS.CANCELLED;

  useEffect(() => {
    if (deliveryRouteData) {
      reset({
        ...deliveryRouteData.data,
        startTime: moment(deliveryRouteData?.data?.startTime) || moment().set({ h: 17, m: 0, s: 0 }), // Adjust this as necessary,
        deliveryArea: {
          ...deliveryRouteData?.data?.deliveryArea,
          type: deliveryRouteData?.data?.deliveryArea?.type || null,
          coordinates: deliveryRouteData?.data?.deliveryArea?.coordinates?.[0] || [],
        },
      });
    }
  }, [deliveryRouteData, reset]);

  const [updateRoute, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useUpdateDeliveryRouteMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Route modified successfully' });
      reset({
        finishAddress: null
      });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
  }, [isSuccess, isError, data, error]);


  if (deliveryRouteLoading) return <TuiSpinner />;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TuiAppBar title="Edit Route" />
        </Grid>
        <Grid
          container
          sx={{ mt: 1 }}
          component='form'
          onSubmit={handleSubmit((userInput) => {
            userInput = {
              ...userInput,
              startTime: moment(userInput.startTime).valueOf(),
            };
            updateRoute(userInput);
          })}>
          {!deliveryRouteLoading && deliveryRouteData?.data && <FormProvider {...methods}>
            <Grid item xs={12} container sx={{ p: 1 }}>
              <RouteForm
                viewOnly={viewOnly}
                item={deliveryRouteData?.data}
              />
            </Grid>
            <Grid item xs={12} container spacing={1} sx={{ height: '100%', position: 'sticky', bottom: 25, p: 2 }}>
              <Grid item xs={6} container spacing={1} alignItems="flex-end">
                <Tooltip
                  arrow
                  title={
                    !settings?.services?.routeRecommendation?.eligible ? (
                      <Alert severity="warning" title="Plan upgrade required for this feature" />
                    ) : !settings?.services?.routeRecommendation?.enable ? (
                      'Enable this feature in Settings'
                    ) : (
                      'Set delivery route area to get route recommendation during customer creation'
                    )
                  }
                  placement="top"
                >
                  <div>
                    <CoreButton
                      fullWidth={false}
                      variant="contained"
                      disabled={!(settings?.services?.routeRecommendation?.enable && settings?.services?.routeRecommendation?.eligible)}
                      onClick={() => setShowRouteAreaDrawer(true)}
                    >
                      <span>Set Route Area</span>
                    </CoreButton>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={6} container justifyContent='flex-end' alignItems="flex-end" spacing={1}>
                <Grid item>
                  <CoreButton
                    isLoading={isLoading}
                    fullWidth={true}
                    onClickHandler={() => navigate(-1)}
                    variant="contained"
                  >
                    Back
                  </CoreButton>
                </Grid>
                <Grid item>
                  <CoreButton
                    disabled={viewOnly}
                    isLoading={isLoading}
                    type='submit'
                    fullWidth={true}
                    variant="contained"
                  >
                    Update
                  </CoreButton>
                </Grid>
              </Grid>
            </Grid>
            <RouteAreaDrawer viewOnly={false} item={deliveryRouteData?.data} open={showRouteAreaDrawer} handleClose={() => setShowRouteAreaDrawer(false)} />
          </FormProvider>}
        </Grid>
      </Grid>
    </>
  );
}
