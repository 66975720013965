import React from 'react';

import DirectionsIcon from '@mui/icons-material/Directions';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import EngineeringIcon from '@mui/icons-material/Engineering';
import DescriptionIcon from '@mui/icons-material/Description';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ExposureIcon from '@mui/icons-material/Exposure';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import LanguageIcon from '@mui/icons-material/Language';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';

export const drawerNavLocations = [
  {
    name: 'Meal Plans',
    path: 'tiffins',
    icon: <TakeoutDiningIcon/>,
    rolesAvailability: 'tiffins',
  }, {
    name: 'Customers',
    path: 'customers',
    icon: <PeopleIcon />,
    rolesAvailability: 'customers',
  }, {
    name: 'Cooking Plan',
    path: 'cooking-plan',
    icon: <OutdoorGrillIcon />,
    rolesAvailability: 'cookingPlan',
  },
  {
    name: 'Packing',
    path: 'packing',
    icon: <LocalMallIcon />,
    rolesAvailability: 'packing',
  },
  {
    name: 'Employees',
    path: 'employees',
    icon: <EngineeringIcon />,
    rolesAvailability: 'employees',
  }, {
    name: 'Routes',
    path: 'routes',
    icon: <DirectionsIcon />,
    rolesAvailability: 'routes',
  }, {
    path: 'reports',
    icon: <AnalyticsIcon />,
    name: 'Reports',
    rolesAvailability: 'reports',
  },{
    path: 'expenses',
    icon: <ExposureIcon />,
    name: 'Expenses',
    rolesAvailability: 'settings',
  },
  {
    path: '/settings',
    icon: <SettingsIcon />,
    name: 'Settings',
    rolesAvailability: 'settings',
    routes: [{
      name: 'General',
      path: 'general',
      icon: <ManageAccountsIcon/>,
      rolesAvailability: 'tiffin',
    },
    {
      name: 'Customer Portal',
      path: 'customer_portal',
      icon: <LanguageIcon/>,
      rolesAvailability: 'tiffin',
    },
    {
      name: 'Business',
      path: 'business',
      icon: <AdminPanelSettingsIcon />,
      rolesAvailability: 'business',
    }, {
      name: 'Billing',
      path: 'billing',
      icon: <DescriptionIcon />,
      rolesAvailability: 'billing',
    }],
  },
];
