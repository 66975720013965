import React from 'react';
import { Grid2 as Grid } from '@mui/material';

import { TuiAppBar, ComingSoon } from '../../../core';

export default function CustomerPortalView() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TuiAppBar
          title="Customer Portal"
        />
      </Grid>
      <ComingSoon />
    </Grid>
  );
}
