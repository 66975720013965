import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';
import PackingView from './views/packing_view';

export default function PackingDetails() {
  return (
    <Routes>
      <Route path="/" element={<PackingView />} />
      <Route path='*' element={<ContentNotFound />} />
    </Routes>
  );
}
