import React from 'react';
import { Grid, Divider, Typography } from '@mui/material';

const TiffinStickers = React.forwardRef(({ data }, ref) => {
  return (
    <Grid container ref={ref}>
      {data?.map((item) => <Grid item xs={4} key={item?.id}>
        <Grid container sx={{ p: 0.2, border: '1px solid' }}>
          <Grid item xs={12}>
            <Typography fontSize={14} noWrap>
              {`${item?.customer?.firstName} ${item?.customer?.lastName}, ${item?.customer?.phoneNumber?.number}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize={12} noWrap>
              {item?.address?.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} container spacing={1}>
            {item?.customer?.tiffin?.items?.map((tiffinItem, index) =>
              <Grid key={`${index}_${tiffinItem?.name}`} item xs="auto">
                <Typography fontSize={12} noWrap>
                  {`${tiffinItem?.name} (${tiffinItem?.quantity})`}
                </Typography>
              </Grid>,
            )}
          </Grid>
        </Grid>
      </Grid>)}
    </Grid>
  );
});

TiffinStickers.displayName = 'TiffinStickers';

export default TiffinStickers;
