import React, { useRef, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';

import { tiffinsApi } from '../../../js/slices/api_slices';
import { TuiAppBar, Table, DatePicker, DataNotFound } from '../../../core';

const { useGetTiffinCookingPlanQuery } = tiffinsApi;

const headCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'quantity',
  title: 'Quantity',
}, {
  id: 'unit',
  title: 'Unit',
}];

const tiffinHeadCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'quantity',
  title: 'Quantity',
}];

export default function TiffinsCookingPlan() {
  const cookingPlanRef = useRef(null);

  const handleCookingPlanPrint = useReactToPrint({
    content: () => cookingPlanRef.current,
  });

  const [selectedDate, setSelectedDate] = useState(moment());
  const {
    data,
    isLoading,
  } = useGetTiffinCookingPlanQuery({
    date: moment(selectedDate).endOf('day').valueOf(),
  }, { refetchOnMountOrArgChange: true });

  const processedItems = data?.data?.items?.map((item) => {
    const { name, quantity, unit } = item;
    return {
      name,
      quantity,
      unit,
      id: name,
    };
  });

  const processedTiffins = data?.data?.tiffins?.map((item) => {
    const { name, quantity } = item;
    return {
      name,
      quantity,
      id: name,
    };
  });

  const totalTiffins = processedTiffins?.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item xs={3}>
          <TuiAppBar
            title="Cooking Plan"
          />
        </Grid>
        <Grid item xs={9} sx={{ mt: 1 }} container justifyContent='flex-end' spacing={1}>
          <Grid item xs='auto'>
            <DatePicker
              onChange={(event) => {
                setSelectedDate(moment(event));
              }}
              value={selectedDate}
              label="Date"
            />
          </Grid>
          <Grid item xs="auto">
            <IconButton
              disabled={!data?.data?.items?.length}
              onClick={handleCookingPlanPrint}
            >
              <PrintIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} container direction='column' justifyContent='center' alignItems='center' sx={{ mt: 10 }}>
        {processedItems?.length > 0 && (
          <div ref={cookingPlanRef}>
            <Grid container spacing={5} style={{ padding: '16px' }}>
              <Grid item xs={6} justifyContent="center" alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography variant='h5' style={{ marginBottom: '16px' }}>Items</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Table headers={headCells} data={processedItems} isLoading={isLoading} />
                </Grid>
              </Grid>
              <Grid item xs={6} container justifyContent="center" alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography variant='h5' style={{ marginBottom: '16px' }}>Tiffins: {totalTiffins}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Table headers={tiffinHeadCells} data={processedTiffins} isLoading={isLoading} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        {!data?.data?.items?.length && <DataNotFound title="cooking plan" />}
      </Grid>
    </Grid >
  );
}
