import { createApi } from '@reduxjs/toolkit/query/react';
import { generateRequestOptions, baseQueryWithReAuth, objectToQueryFormat } from '../../actions/action_helper';

const tiffinsApi = createApi({
  reducerPath: 'tiffinsApi',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['Tiffins', 'Tiffin Subscribers', 'Tiffin Subscribers Stats', 'Tiffin Items'],
  endpoints: (builder) => ({
    createTiffinSubscriber: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'app/subscribers',
          method: 'POST',
          data,
        }),
      }),
    }),
    updateTiffinSubscriber: builder.mutation({
      query: ({ id, data }) => ({
        ...generateRequestOptions({
          url: `/app/subscribers/${id}`,
          method: 'PUT',
          data,
        }),
      }),
    }),
    deleteTiffinSubscriber: builder.mutation({
      query: ({ id, data }) => ({
        ...generateRequestOptions({
          url: `/app/subscribers/${id}`,
          method: 'DELETE',
          data,
        }),
      }),
    }),
    getTiffinSubscribers: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/subscribers?${objectToQueryFormat(args)}`,
          method: 'GET',
          data: null, // Add the missing data property
        }),
      }),
    }),
    getTiffinSubscriber: builder.query({
      query: ({ id }) => ({
        ...generateRequestOptions({
          url: `/app/subscribers/${id}`,
          method: 'GET',
        }),
      }),
    }),
    createTiffin: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'app/tiffins',
          method: 'POST',
          data,
        }),
      }),
    }),
    updateTiffin: builder.mutation({
      query: ({ id, data }) => ({
        ...generateRequestOptions({
          url: `/app/tiffins/${id}`,
          method: 'PUT',
          data,
        }),
      }),
    }),
    deleteTiffin: builder.mutation({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/tiffins/${args?.id}`,
          method: 'DELETE',
        }),
      }),
    }),
    createTiffinItem: builder.mutation({
      query: (data) => ({
        ...generateRequestOptions({
          url: 'app/tiffins/items',
          method: 'POST',
          data,
        }),
      }),
    }),
    getTiffinItems: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/tiffins/items?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getTiffins: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/tiffins?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getTiffin: builder.query({
      query: ({ id }) => ({
        ...generateRequestOptions({
          url: `/app/tiffins/${id}`,
          method: 'GET',
        }),
      }),
    }),
    getTiffinSubscriberStats: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/subscribers/stats?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getTiffinCookingPlan: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/tiffins/cooking-plan?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getTiffinStickers: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `/app/tiffins?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getTiffinSubscriberWithRouteAndDriver: builder.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const subscriberDetails = await fetchWithBQ(`/app/subscribers/${_arg.id}`);
        if (subscriberDetails?.error) return subscriberDetails?.error;

        if (subscriberDetails?.data?.data?.route?.id === null) return subscriberDetails;
        const deliveryRoute = await fetchWithBQ(`/app/delivery_routes/${subscriberDetails?.data?.data?.route?.id}`);
        if (!deliveryRoute?.error) {
          subscriberDetails['data']['data']['route'] = deliveryRoute?.data?.data;
        }

        return subscriberDetails;
      },
    }),
  }),
});

export const {
  useGetTiffinSubscribersQuery,
  useLazyGetTiffinSubscribersQuery,
  useGetTiffinSubscriberQuery,
  useLazyGetTiffinSubscriberQuery,
  useCreateTiffinSubscriberMutation,
  useUpdateTiffinSubscriberMutation,
  useGetTiffinsQuery,
  useGetTiffinQuery,
  useCreateTiffinMutation,
  useCreateTiffinItemMutation,
  useGetTiffinItemsQuery,
  useUpdateTiffinMutation,
  useDeleteTiffinMutation,
  useGetTiffinSubscriberStatsQuery,
  useGetTiffinCookingPlanQuery,
  useGetTiffinStickersQuery,
  useGetTiffinSubscriberWithRouteAndDriverQuery,
} = tiffinsApi;

export default tiffinsApi;
