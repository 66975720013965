import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import TiffinSubscriberForm from '../components/customer_form';
import TiffinSubscriberGoodsForm from '../components/customer_tiffin_goods_form';
import { tiffinsApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, TuiSpinner } from '../../../core/index';

const { useUpdateTiffinSubscriberMutation, useGetTiffinSubscriberWithRouteAndDriverQuery } = tiffinsApi;

export default function EditCustomer() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();
  const { data: fetchedData, isLoading: itemLoading } = useGetTiffinSubscriberWithRouteAndDriverQuery({
    id,
  }, { refetchOnMountOrArgChange: true, skip: !id });

  const { register, handleSubmit, formState: { errors, isDirty }, reset, control, setValue, watch, getValues } = useForm({
    defaultValues: fetchedData?.data,
  });

  const [updateTiffinSubscriber, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useUpdateTiffinSubscriberMutation();

  useEffect(() => {
    reset(fetchedData?.data);
  }, [fetchedData]);

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Customer data modified successfully' });
      reset(data?.data);
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
  }, [isSuccess, isError, data, error]);

  if (itemLoading) {
    return <TuiSpinner />;
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TuiAppBar title={'Edit Customer'} />
      </Grid>
      <Grid
        container
        item
        xs={12}
        height='93vh'
        component='form'
        onSubmit={handleSubmit((userInput) => {
          userInput.startDate = moment(userInput.startDate).startOf('day').valueOf();
          userInput.endDate = moment(userInput.endDate).endOf('day').valueOf();
          if (!userInput.route?.id) {
            userInput.route = {
              id: null,
            };
          }
          updateTiffinSubscriber({ id, data: userInput });
        })}>
        <Grid item xs={12} container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={7}>
            <TiffinSubscriberForm
              action='update'
              viewOnly={false}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              item={fetchedData?.data}
              control={control}
              register={register}
              errors={errors}
            />
          </Grid>
          <Grid item xs={5}>
            <TiffinSubscriberGoodsForm
              reset={reset}
              action='update'
              control={control}
              item={fetchedData?.data}
              watch={watch}
              isDirty={isDirty}
              isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
