import React, { useEffect } from 'react';
import moment from 'moment';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import TiffinSubscriberForm from '../components/customer_form';
import TiffinSubscriberGoodsForm from '../components/customer_tiffin_goods_form';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { TIFFIN, DELIVERY_METHOD, STORAGE_KEYS } from '../../../js/lib/constants';

import { TuiAppBar, Hooks } from '../../../core/index';

const { useSnackBarNotification, usePersist } = Hooks;
const { useCreateTiffinSubscriberMutation } = tiffinsApi;

export default function CreateCustomerView() {
  const navigate = useNavigate();
  const settings = useSelector((store) => store?.settings?.settings);

  let initialData = sessionStorage.getItem(STORAGE_KEYS.CUSTOMER);
  if (initialData) {
    try {
      let initialJsonData = JSON.parse(initialData);
      initialData = initialJsonData;
    } catch (err) {
      console.error('Error parsing initialData', err);
    }
  } else {
    initialData = {
      shipping: DELIVERY_METHOD.PICK_UP,
      deliveryDays: settings?.deliveryDays,
      tiffin: {
        name: '',
        plan: {
          type: TIFFIN.PLANS.MONTHLY,
          count: 1,
          price: 0,
        },
      },
      route: {
        id: null,
      },
    }
  }

  const { register, handleSubmit, formState: { errors, isDirty }, control, watch, getValues, setValue, reset } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
  });

  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [addNewSubscriber, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useCreateTiffinSubscriberMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Customer created successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
    return () => {
      if (isSuccess) {
        sessionStorage.removeItem(STORAGE_KEYS.CUSTOMER);
      }
    }
  }, [isSuccess, isError, data, error]);

  usePersist({ value: watch(), sessionStorageKey: STORAGE_KEYS.CUSTOMER });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TuiAppBar title='New Customer' />
        </Grid>
        <Grid
          height='90vh'
          component='form'
          direction="column"
          onSubmit={handleSubmit((userInput) => {
            userInput.startDate = moment(userInput.startDate).startOf('day').valueOf();
            userInput.endDate = moment(userInput.endDate).endOf('day').valueOf();
            if (!userInput.route?.id) {
              userInput.route = {
                id: null,
              };
            }
            addNewSubscriber(userInput);
          })}
          sx={{ mt: 1 }}
          container
        >
          <Grid item xs={12} container spacing={1} sx={{ p: 1, height: 'inherit' }}>
            <Grid item xs={7}>
              <TiffinSubscriberForm
                action='create'
                setValue={setValue}
                getValues={getValues}
                control={control}
                register={register}
                errors={errors}
                viewOnly={false}
                watch={watch}
              />
            </Grid>
            <Grid item xs={5}>
              <TiffinSubscriberGoodsForm
                reset={reset}
                isDirty={isDirty}
                action='create'
                item={null}
                errors={errors}
                control={control}
                isLoading={isLoading} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
