import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Alert, Grid, Tooltip } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RouteForm from '../components/route_form';
import { deliveryRoutesApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, CoreButton } from '../../../core/index';
import { ROUTE, STORAGE_KEYS } from '../../../js/lib/constants';
import RouteAreaDrawer from '../components/route_area_drawer';

const { useSnackBarNotification, usePersist } = Hooks;
const { useCreateDeliveryRouteMutation } = deliveryRoutesApi;

export default function CreateRouteView() {
  const navigate = useNavigate();
  const [showRouteAreaDrawer, setShowRouteAreaDrawer] = useState(false);
  const settings = useSelector((store) => store?.settings?.settings);
  let initialData = sessionStorage.getItem(STORAGE_KEYS.ROUTE);
  if (initialData) {
    try {
      let initialJsonData = JSON.parse(initialData);
      initialJsonData.startTime = moment(initialJsonData?.startTime);
      initialData = initialJsonData;
    } catch (err) {
      console.error('Error parsing initialData', err);
    }
  } else {
    initialData = {
      startTime: moment().set({ h: 17, m: 0, s: 0 }),
      startAddress: settings?.address,
      finishAddress: null,
      type: ROUTE.TYPES.PERMANENT,
      deliveryArea: {
        type: null,
        coordinates: [],
      }
    }
  }

  const methods = useForm({
    defaultValues: initialData,
    mode: 'onChange',
  });

  const { handleSubmit, watch } = methods;

  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [addNewRoute, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useCreateDeliveryRouteMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Route created successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
    return () => {
      if (isSuccess) {
        sessionStorage.removeItem(STORAGE_KEYS.ROUTE);
      }
    }
  }, [isSuccess, isError, data, error]);

  usePersist({ value: watch(), sessionStorageKey: STORAGE_KEYS.ROUTE });

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TuiAppBar title='New Route' />
        </Grid>
        <Grid
          component='form'
          onSubmit={handleSubmit((userInput) => {
            userInput.startTime = moment(userInput?.startTime).valueOf();
            addNewRoute(userInput);
          })}
          sx={{ mt: 1 }}
          container
        >
          <FormProvider {...methods}>
            <Grid item xs={12} container sx={{ p: 1, height: 'inherit' }}>
              <RouteForm
                viewOnly={false}
                item={null}
              />
            </Grid>
            <Grid item xs={12} container spacing={1} sx={{ height: '100%', position: 'sticky', bottom: 25, p: 2 }}>
              <Grid item xs={6} container spacing={1} alignItems="flex-end">
                <Tooltip
                  arrow
                  title={
                    !settings?.services?.routeRecommendation?.eligible ? (
                      <Alert severity="warning" title="Plan upgrade required for this feature" />
                    ) : !settings?.services?.routeRecommendation?.enable ? (
                      'Enable this feature in Settings'
                    ) : (
                      'Set delivery route area to get route recommendation during customer creation'
                    )
                  }
                  placement="top"
                >
                  <div>
                    <CoreButton
                      fullWidth={false}
                      variant="contained"
                      disabled={!(settings?.services?.routeRecommendation?.enable && settings?.services?.routeRecommendation?.eligible)}
                      onClick={() => setShowRouteAreaDrawer(true)}
                    >
                      <span>Set Route Area</span>
                    </CoreButton>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={6} container justifyContent='flex-end' alignItems="flex-end" spacing={1}>
                <Grid item>
                  <CoreButton
                    isLoading={isLoading}
                    fullWidth={true}
                    onClickHandler={() => navigate(-1)}
                    variant="contained"
                  >
                    Back
                  </CoreButton>
                </Grid>
                <Grid item>
                  <CoreButton
                    isLoading={isLoading}
                    type='submit'
                    fullWidth={true}
                    variant="contained"
                  >
                    Add
                  </CoreButton>
                </Grid>
              </Grid >
            </Grid >
            <RouteAreaDrawer viewOnly={false} item={null} open={showRouteAreaDrawer} handleClose={() => setShowRouteAreaDrawer(false)} />
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
}
