import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { BaseTextField, TuiAutoComplete, Hooks } from '../../../core/index';
import { googleMapsApi } from '../../../js/slices/api_slices';
import { SALARY_TYPES, USERS } from '../../../js/lib/constants';

const { useGetPlacesQuery } = googleMapsApi;
const userTypes = [
  USERS.TYPES.EMPLOYEE.COOK,
  USERS.TYPES.EMPLOYEE.DRIVER,
  USERS.TYPES.EMPLOYEE.PACKER];

export default function EmployeeForm({ register, errors, viewOnly, control, watch, getValues, item = null, action }) {
  const [addressInputValue, setAddressInputValue] = useState('');
  const { id } = useParams();
  const debouncedAddressInput = Hooks.useDebounce(addressInputValue, 500);

  const { data: addressData,
    isLoading: addressLoading } = useGetPlacesQuery({
      input: debouncedAddressInput,
    }, { refetchOnMountOrArgChange: true, skip: addressInputValue ? false : true });

  return (
    <Grid
      sx={{
        backgroundColor: 'white',
        height: '100%',
        p: 2
      }}>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}
          container sx={{
            p: 1.2,
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
          }}>
          <Grid item xs={12}>
            <Typography>Personal Details</Typography>
          </Grid>
          <Grid item container xs={12} sx={{ mt: 1 }} spacing={1}>
            <Grid item xs={3}>
              <BaseTextField
                id='firstName'
                size="small"
                name="firstName"
                label="First Name"
                disabled={viewOnly}
                errors={errors}
                value={watch('firstName')}
                validate={register('firstName', {
                  required: 'First name is required',
                  maxLength: {
                    value: 1000,
                    message: 'Length must be less than 500',
                  },
                },
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='lastName'
                size="small"
                name="lastName"
                label="Last Name"
                required={false}
                disabled={viewOnly}
                value={watch('lastName')}
                errors={errors}
                validate={register('lastName', {
                  maxLength: {
                    value: 1000,
                    message: 'Length must be less than 500',
                  },
                },
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='phoneNumber'
                size="small"
                name="phoneNumber"
                label="Phone Number"
                disabled={viewOnly}
                errors={errors}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                }}
                validate={register('phoneNumber.number', {
                  required: 'Phone number is required',
                  maxLength: {
                    value: 10,
                    message: 'Length must be 10',
                  },
                },
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='email'
                size="small"
                name="email"
                label="Email"
                type="email"
                disabled={viewOnly || !!id} // Do not allow editing
                errors={errors}
                validate={register('email', {
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                  },
                },
                )}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sx={{ mt: 1 }} spacing={1}>
            <Grid item xs={2}>
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="type"
                  defaultValue={item?.type || ''}
                  render={({ field }) => {
                    return (
                      <FormControl
                        required={true}
                        fullWidth
                        size="small">
                        <InputLabel
                          sx={{
                            color: errors?.['type']?.message ? 'secondary.contrastText.100' : 'default',
                          }}>
                          Type
                        </InputLabel>
                        <Select
                          id="type"
                          error={!!errors?.['type']?.message}
                          disabled={action === 'update' || viewOnly}
                          label="Type"
                          onChange={(event) => field.onChange(event.target.value)}
                          value={field?.value} >
                          {userTypes.map((item) => <MenuItem key={item} value={item}>{item}</MenuItem>)}
                        </Select>
                        <Typography fontSize={12} mt={1} ml={2} color='secondary.contrastText.100'>{errors?.type?.message}</Typography>
                      </FormControl>
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Controller
                control={control}
                name="address"
                defaultValue={item?.address || ''}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TuiAutoComplete
                      name="address"
                      id="address"
                      loading={addressLoading}
                      freeSolo
                      errors={errors}
                      onChange={(event, item) => {
                        onChange(item);
                      }}
                      required={false}
                      inputValue={addressInputValue}
                      onInputChange={(event, newInputValue) => {
                        setAddressInputValue(newInputValue);
                      }}
                      value={value}
                      label="Address"
                      labelKey='description'
                      options={addressData?.data?.items}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='sinNumber'
                size="small"
                name="sinNumber"
                label="SIN Number"
                disabled={viewOnly}
                errors={errors}
                required={false}
                validate={register('sinNumber', {
                  maxLength: {
                    value: 500,
                    message: 'Length must be less than 500',
                  },
                },
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='licenseNumber'
                size="small"
                name="licenseNumber"
                label="License Number"
                disabled={viewOnly}
                errors={errors}
                required={false}
                validate={register('licenseNumber', {
                  maxLength: {
                    value: 500,
                    message: 'Length must be less than 500',
                  },
                },
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        {watch('type') === USERS.TYPES.EMPLOYEE.DRIVER && <Grid item xs={12}
          container sx={{
            mt: 3,
            p: 1.2,
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
          }}>
          <Grid item xs={12}>
            <Typography>Vehicle Details</Typography>
          </Grid>
          <Grid item container xs={12} sx={{ mt: 1 }} spacing={1}>
            <Grid item xs={3}>
              <BaseTextField
                id='vehicle.name'
                size="small"
                name="vehicle.name"
                label="Make and Model"
                disabled={viewOnly}
                errors={errors}
                required={false}
                validate={register('vehicle.name', {
                  maxLength: {
                    value: 500,
                    message: 'Length must be less than 500',
                  },
                },
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='vehicle.plateNumber'
                size="small"
                name="vehicle.plateNumber"
                label="Plate Number"
                disabled={viewOnly}
                required={false}
                errors={errors}
                validate={register('vehicle.plateNumber', {
                  maxLength: {
                    value: 500,
                    message: 'Length must be less than 500',
                  },
                },
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='vehicle.vinNumber'
                size="small"
                name='vehicle.vinNumber'
                label="VIN Number"
                disabled={viewOnly}
                required={false}
                errors={errors}
                validate={register('vehicle.vinNumber', {
                  maxLength: {
                    value: 500,
                    message: 'Length must be 500',
                  },
                },
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='vehicle.color'
                size="small"
                name="vehicle.color"
                label="Color"
                disabled={viewOnly}
                errors={errors}
                required={false}
                validate={register('vehicle.color', {
                  maxLength: {
                    value: 100,
                    message: 'Length must be 100',
                  },
                },
                )}
              />
            </Grid>
          </Grid>
        </Grid>}
        <Grid item xs={12}
          container sx={{
            mt: 3,
            p: 1.2,
            borderRadius: '20px',
            border: '1px solid #E0E0E0',
          }}>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography>Salary Details</Typography>
          </Grid>
          <Grid item xs={12} container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={3}>
              <Controller
                control={control}
                name="salary.type"
                render={({ field }) => {
                  return (
                    <FormControl fullWidth size="small">
                      <InputLabel
                        id="salary.type"
                        sx={{
                          color: errors?.['salary.type']?.message ? 'secondary.contrastText.100' : 'default',
                        }}>
                        Salary Type
                      </InputLabel>
                      <Select
                        disabled={viewOnly}
                        labelId="salary.type"
                        id="salary.type"
                        error={!!errors?.['salary.type']?.message}
                        label="Salary Type"
                        onChange={(event) => field.onChange(event.target.value)}
                        value={field.value || ''} // replace null with an empty string
                      >
                        {Object.values(SALARY_TYPES).map((type) => <MenuItem key={type} value={type}>{type}</MenuItem>)}
                      </Select>
                      <Typography fontSize={12} mt={1} ml={2} color='secondary.contrastText.100'>{errors?.['label']?.message}</Typography>
                    </FormControl>
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='salary.value'
                size="small"
                name="salary.value"
                label="Salary"
                disabled={viewOnly}
                type='number'
                errors={errors}
                required={false}
                validate={register('salary.value')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
