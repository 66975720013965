import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import TiffinForm from '../components/tiffin_form';
import TiffinGoodsForm from '../components/tiffin_goods_form';
import { tiffinsApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks, TuiSpinner } from '../../../core/index';

const { useUpdateTiffinMutation, useGetTiffinQuery } = tiffinsApi;

export default function EditItem() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { errorSnackBar, successSnackBar } = Hooks.useSnackBarNotification();

  const { data: fetchedData, isLoading: itemLoading } = useGetTiffinQuery({
    id,
  }, { refetchOnMountOrArgChange: true, skip: !id });

  const [updateTiffin, {
    data: updateData,
    isSuccess: updateIsSuccess,
    error: updateError,
    isError: updateIsError,
    isLoading: updateIsLoading,
  }] = useUpdateTiffinMutation();

  const { register, handleSubmit, formState: { errors }, reset, control, setValue, watch, getValues } = useForm({
    defaultValues: {
      ...fetchedData?.data,
      pickUpPriceChange: (fetchedData?.data?.price?.delivery?.single !== fetchedData?.data?.price?.pickUp?.single ||
        fetchedData?.data?.price?.delivery?.weekly !== fetchedData?.data?.price?.pickUp?.weekly ||
        fetchedData?.data?.price?.delivery?.monthly !== fetchedData?.data?.price?.pickUp?.monthly) || false,
    },
  });

  useEffect(() => {
    if (updateIsSuccess && updateData) {
      successSnackBar({ message: 'Tiffin Item/s updated successfully' });
      navigate(-1);
    } if (updateError && updateIsError) {
      errorSnackBar({ message: updateError?.data?.errorMessage });
    }
  }, [updateIsSuccess, updateData, updateError, updateIsError]);

  useEffect(() => {
    reset({
      ...fetchedData?.data,
      pickUpPriceChange: (fetchedData?.data?.price?.delivery?.single !== fetchedData?.data?.price?.pickUp?.single ||
        fetchedData?.data?.price?.delivery?.weekly !== fetchedData?.data?.price?.pickUp?.weekly ||
        fetchedData?.data?.price?.delivery?.monthly !== fetchedData?.data?.price?.pickUp?.monthly) || false,
    });
  }, [fetchedData]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TuiAppBar title='Edit Meal Plan' />
      </Grid>
      {itemLoading && <TuiSpinner />}
      {!itemLoading && <Grid
        container
        item
        xs={12}
        height='90vh'
        component='form'
        onSubmit={handleSubmit((userInput) => {
          updateTiffin({ id, data: userInput });
        })}>
        <Grid item xs={12} container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={6}>
            <TiffinForm
              action='update'
              getValues={getValues}
              setValue={setValue}
              item={fetchedData?.data}
              control={control}
              register={register}
              errors={errors}
              watch={watch}
            />
          </Grid>
          <Grid item xs={6}>
            <TiffinGoodsForm
              action='update'
              watch={watch}
              reset={reset}
              item={fetchedData?.data}
              control={control}
              isLoading={updateIsLoading} />
          </Grid>
        </Grid>
      </Grid>}
    </Grid>
  );
}
