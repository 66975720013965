import React from 'react';
import {Route, Routes} from 'react-router-dom';

import ContentNotFound from '../errors/components/content_not_found';

import TiffinListView from './views/tiffins_view';
import CreateTiffinItemView from './views/create_tiffin_view';
import EditTiffinItemView from './views/edit_tiffin_view';

export default function TiffinRoutes() {
  return (
    <Routes>
        <Route path="/" element={<TiffinListView />}/>
        <Route path="new" element={<CreateTiffinItemView />}/>
        <Route path=":id" element = {<EditTiffinItemView />} />
        <Route path='*' element ={<ContentNotFound />} />
    </Routes>
  );
}
