import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Grid, Typography, Link, InputAdornment, IconButton} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import {CoreButton, BaseTextField, PublicContainer, BaseOtpModal, Hooks} from '../../../core';
import {authApi} from '../../../js/slices/api_slices';

const {useSnackBarNotification} = Hooks;
const {useCodeGenerationMutation, useRegistrationMutation, usePreCheckMutation} = authApi;

const SignupComponent = function() {
  const {register, handleSubmit, watch, getValues, setValue, formState: {errors}} = useForm({
    mode: 'onChange',
  });
  const [openOtpModal, setOtpState] = useState(false);
  const {errorSnackBar} = useSnackBarNotification();
  const [codeGenerationMutation, {
    isLoading,
    error,
    isError,
    isSuccess,
  }] = useCodeGenerationMutation();
  const [showPassword, setShowPassword] = useState(false);

  const [preCheckMutation] = usePreCheckMutation();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    if (isError && error) {
      errorSnackBar({message: error?.data?.errorMessage});
    }
    if (isSuccess) {
      setOtpState(true);
    }
  }, [isError, error, isSuccess]);

  const component = <>
    <Grid container justifyContent='center'>
      <Grid
        item
        component='form'
        container
        sx={{maxWidth: 400, p: 1}}
        spacing={2}
        onSubmit={handleSubmit(async (userInput) => {
          codeGenerationMutation({...userInput, type: 'registration'});
        })}
      >
        <Grid item xs={12} container justifyContent='center'>
          <Typography variant="h5" fontWeight="bold" >
          Sign Up
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent='center'>
          <Typography mb={4} color="secondary.contrastText.main">
          Fill the details below to continue
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <BaseTextField
            id="email"
            name="email"
            label="Email Address"
            errors={errors}
            validate={register('email', {
              required: 'Email is required',
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: 'Email must be valid',
              },
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <BaseTextField
            id="businessName"
            name="businessName"
            label="Business Name"
            errors={errors}
            validate={register('businessName', {
              required: 'Business Name is required',
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <BaseTextField
            id="password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            errors={errors}
            validate={register('password', {
              required: 'Password is required',
              minLength: {
                value: 6,
                message: 'Password must be greater then 6 characters',
              },
            })}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff sx={{color: 'secondary.contrastText'}}/> : <Visibility sx={{color: 'secondary.contrastText'}}/>}
                </IconButton>
              </InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <BaseTextField
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type={showPassword ? 'text' : 'password'}
            errors={errors}
            validate={register('confirmPassword', {
              required: 'Please confirm Password',
              validate: (value) => {
                if (watch('password') !== value) {
                  return 'You password does not match';
                }
              },
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <CoreButton
            fullWidth={true}
            type='submit'
            variant='contained'
            isLoading={isLoading}
            color="primary"
          >
          Sign Up
          </CoreButton>
        </Grid>
        <Grid item xs={12} sx={{pt: 1}}>
          <Typography fontSize={15} >
          Already a member? <Link href='/login'>Login</Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <BaseOtpModal
      open={openOtpModal}
      type="registration"
      navigateToRoute="/login"
      userDetails={getValues()}
      resendOTPHandler={codeGenerationMutation}
      handleClose={() => setOtpState(false)}
      postGenerationHook={useRegistrationMutation}
    />
  </>;

  return (
    <PublicContainer component={component} />
  );
};

export default SignupComponent;

