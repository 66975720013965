import React, {useEffect} from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import {DAYS} from '../js/lib/constants';

const ToggleDays = ({field, existingDays, disabled = false}) => {
  useEffect(() => {
    if (existingDays?.length) {
      field?.onChange(existingDays);
    }
  }, [existingDays?.length]);

  return (
    <ToggleButtonGroup
      onChange={(event, value) => field?.onChange(value)}
      value={field?.value || existingDays}
      sx={{
        '.MuiToggleButtonGroup-grouped': {
          'mx': 1,
          '&:not(:first-of-type)': {
            borderRadius: '50%',
            borderColor: 'secondary.main',
          },
          '&:first-of-type': {
            borderRadius: '50%',
            borderColor: 'secondary.main',
          },
        },
      }}
      size="small"
      arial-label="Days of the week"
    >
      {DAYS.map((day) => (
        <ToggleButton
          sx={{
            'minWidth': 32,
            'maxWidth': 32,
            'height': 32,
            'textTransform': 'unset',
            'fontSize': '0.75rem',
            '&.Mui-selected, &.Mui-selected:hover': {
              color: 'white',
              backgroundColor: 'secondary.contrastText',
              borderColor: 'secondary.contrastText',
            },
            '&.Mui-selected:disabled': {
              color: 'white',
              backgroundColor: 'secondary.disabledBackground',
              borderColor: 'secondary.disabledBackground',
            },
          }}
          disabled={disabled}
          key={day.value}
          value={day.value}
          aria-label={day.key}>
          {day.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleDays;
