import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import BasePagination from '../../../core/base_pagination';
import { tiffinsApi } from '../../../js/slices/api_slices';
import TiffinListComponent from '../components/tiffin_list_component';
import DataNotFound from '../../../core/data_not_found';
import { TuiAppBar, Hooks, TuiSpinner } from '../../../core';
import { useDebounce } from '../../../core/hooks';

const { useGetTiffinsQuery } = tiffinsApi;
const { useResponsivePageSize, usePageNumberAndSearch } = Hooks;

export default function TiffinItemsView() {
  const [pageSize, setPageSize] = useResponsivePageSize();
  const roles = useSelector((store) => store?.roles.roles);
  const navigate = useNavigate();
  const [page, setPage, searchText, setSearchText] = usePageNumberAndSearch();
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [rows, setRows] = useState([]);

  const { data, isLoading, isSuccess } = useGetTiffinsQuery({
    page,
    pageSize,
    contains: { 'name': debouncedSearchTerm },
    sortKey: '+name',
  }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isSuccess && !isLoading) {
      setRows(data?.data?.items);
    }
  }, [isLoading, isSuccess, data?.data?.items]);

  if (isLoading) {
    return <TuiSpinner />;
  }

  return (
    <>
      <Grid container spacing={1} sx={{ height: data?.data?.items?.length <= 0 ? '100%' : null }}>
        <Grid item xs={12} md={12} lg={12}>
          <TuiAppBar
            buttonDisabled={!roles?.tiffins?.create}
            buttonClickHandler={() => {
              navigate('new');
            }}
            setSearchText={setSearchText}
            searchText={searchText}
            searchHelperText='Name'
            title="Meal Plans"
          />

        </Grid>
        <Grid item xs={12}>
          {!data?.data?.items?.length && <DataNotFound title="tiffins" />}
        </Grid>
        <Grid item xs={12}>
          {!isLoading && rows?.length > 0 &&
            <TiffinListComponent
              isLoading={isLoading}
              setPage={setPage}
              page={page}
              setRows={setRows}
              rows={rows}
            />
          }
        </Grid>
      </Grid>
      <Grid container justifyContent='flex-end' alignItems="flex-end" sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <BasePagination
          data={data}
          pageSize={pageSize}
          page={page - 1}
          pageSizeChangeHandler={(event) => {
            setPageSize(parseInt(event.target.value));
            setPage(1);
          }}
          pageChangeHandler={(event, val) => {
            setPage(val + 1);
          }}
        />
      </Grid>
    </>
  );
}
