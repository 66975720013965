import { configureStore } from '@reduxjs/toolkit';
import { reducerSlices, apiSlices } from '../js/slices/index';

const { authApi,
  rolesApi,
  employeesApi,
  settingsApi,
  tiffinsApi,
  businessApi,
  notificationsApi,
  deliveryRoutesApi,
  shippingStatusApi,
  tiffinPaymentApi,
  stopsApi,
  googleMapsApi } = apiSlices;
const { userReducer, rolesReducer, settingsReducer, businessReducer } = reducerSlices;

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    user: userReducer,
    roles: rolesReducer,
    settings: settingsReducer,
    business: businessReducer,
    [authApi.reducerPath]: authApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [employeesApi.reducerPath]: employeesApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [googleMapsApi.reducerPath]: googleMapsApi.reducer,
    [tiffinsApi.reducerPath]: tiffinsApi.reducer,
    [businessApi.reducerPath]: businessApi.reducer,
    [notificationsApi.reducerPath]: tiffinsApi.reducer,
    [deliveryRoutesApi.reducerPath]: deliveryRoutesApi.reducer,
    [shippingStatusApi.reducerPath]: shippingStatusApi.reducer,
    [tiffinPaymentApi.reducerPath]: tiffinPaymentApi.reducer,
    [stopsApi.reducerPath]: stopsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
    .concat(authApi.middleware)
    .concat(rolesApi.middleware)
    .concat(employeesApi.middleware)
    .concat(settingsApi.middleware)
    .concat(tiffinsApi.middleware)
    .concat(googleMapsApi.middleware)
    .concat(businessApi.middleware)
    .concat(deliveryRoutesApi.middleware)
    .concat(shippingStatusApi.middleware)
    .concat(tiffinPaymentApi.middleware)
    .concat(stopsApi.middleware)
    .concat(notificationsApi.middleware),
});

export default store;
