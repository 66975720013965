import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  TableSortLabel,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';

import TuiSpinner from './base_spinner';

export default function TuiTable({ headers, data = [], isLoading, selectedRow, selectAllChecked, selectAllHandler }) {
  const tableHeaders = <TableHead sx={{ backgroundColor: 'grey.300' }}>
    <TableRow>
      {headers
        .map(({ title, id, numeric = false, disablePadding = false }) => {
          if (id === 'checkbox') {
            return (<TableCell
              key={id}
              align={numeric ? 'right' : 'left'}
              padding={disablePadding ? 'none' : 'normal'}>
              <Checkbox checked={selectAllChecked} onClick={selectAllHandler}/>
            </TableCell>)
          }
          return (<TableCell
            key={id}
            sx={{ fontWeight: 'bold', fontSize: 16 }}
            align={numeric ? 'right' : 'left'}
            padding={disablePadding ? 'none' : 'normal'}>
            <TableSortLabel>
              {title}
            </TableSortLabel>
          </TableCell>)
        })}
    </TableRow>
  </TableHead>;

  const getTableCells = (valuesArr) => {
    return valuesArr.map((item, i) => <TableCell
      key={i}>
      {item}
    </TableCell>);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        {tableHeaders}
        <TableBody>
          {isLoading && <TuiSpinner />}
          {data.map((item) => {
            const newItem = { ...item };
            delete item?.id;
            const allValues = Object.values(item);
            return (
              <TableRow
                key={newItem.id || Math.random()}
                onClick={() => selectedRow(newItem)}
                tabIndex={-1}>
                {getTableCells(allValues)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer >
  );
};

TuiTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  headers: PropTypes.array.isRequired,
};