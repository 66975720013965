import React from 'react';
import {Grid} from '@mui/material';

import Copyright from './copyright';
import Logo from '../assets/images/dinespot_black_logo.png';

export default function PublicContainer({component}) {
  return (
    <Grid
      container
      height={'100vh'}
    >
      <Grid item xs={12} container justifyContent='flex-start' sx={{maxHeight: 20, p: 2}}>
        <img src={Logo} alt='DareDevilsBar Logo' width="15%" />
      </Grid>
      <Grid item xs={12} container justifyContent='center' alignContent='center' alignItems='center'>
        {component}
      </Grid>
      <Grid item xs={12} container justifyContent='center' alignContent='flex-end'>
        <Copyright />
      </Grid>
    </Grid>
  );
};
