import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import TiffinForm from '../components/tiffin_form';
import TiffinGoodsForm from '../components/tiffin_goods_form';
import { tiffinsApi } from '../../../js/slices/api_slices';

import { TuiAppBar, Hooks } from '../../../core/index';
import { STORAGE_KEYS } from '../../../js/lib/constants';

const { useSnackBarNotification, usePersist } = Hooks;
const { useCreateTiffinMutation } = tiffinsApi;

export default function CreteTiffinView() {
  const navigate = useNavigate();

  let initialData = sessionStorage.getItem(STORAGE_KEYS.TIFFIN);
  if (initialData) {
    try {
      let initialJsonData = JSON.parse(initialData);
      initialData = initialJsonData;
    } catch (err) {
      console.error('Error parsing initialData', err);
    }
  } else {
    initialData = {
      goodsCostPercent: 0,
      items: [],
    }
  }

  const { register, handleSubmit, formState: { errors }, control, watch, setValue, reset } = useForm({
    defaultValues: {
      ...initialData,
      pickUpPriceChange: false
    },
  });
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [addNewTiffin, {
    data,
    isSuccess,
    error,
    isError,
    isLoading,
  }] = useCreateTiffinMutation();

  useEffect(() => {
    if (isSuccess && data) {
      successSnackBar({ message: 'Tiffin created successfully' });
      navigate(-1);
    } if (isError && error) {
      errorSnackBar({ message: error?.data?.errorMessage });
    }
    return () => {
      if (isSuccess) {
        sessionStorage.removeItem(STORAGE_KEYS.TIFFIN);
      }
    }
  }, [isSuccess, isError, data, error]);

  usePersist({ value: watch(), sessionStorageKey: STORAGE_KEYS.TIFFIN });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TuiAppBar title='New Meal Plan' />
      </Grid>
      <Grid
        height='90vh'
        component='form'
        onSubmit={handleSubmit((userInput) => {
          addNewTiffin(userInput);
        })}
        sx={{ mt: 1 }}
        container
        direction="column">
        <Grid item xs={12} container spacing={2} sx={{ p: 1, height: 'inherit' }}>
          <Grid item xs={6}>
            <TiffinForm
              action='create'
              setValue={setValue}
              control={control}
              register={register}
              errors={errors}
              watch={watch}
            />
          </Grid>
          <Grid item xs={6}>
            <TiffinGoodsForm
            reset={reset}
              action='create'
              watch={watch}
              errors={errors}
              control={control}
              isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
