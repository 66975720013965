import React from 'react';
import { Grid2 as Grid } from '@mui/material';

import { TuiAppBar, ComingSoon } from '../../../core';

export default function ExpensesView() {
  return (
    <Grid container>
      <Grid size={12}>
        <TuiAppBar
          title="Expenses"
        />
      </Grid>
      <ComingSoon />
    </Grid>
  );
}
