import React, { useState, useEffect } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Chip, Grid, Box, Modal, Typography, IconButton, Alert, Checkbox, Select, FormControl, MenuItem, OutlinedInput, InputLabel, InputAdornment } from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import {
  DataGrid,
} from '@mui/x-data-grid';

import { BaseTextField, CoreButton, Hooks, TuiAutoComplete } from '../../../core';
import { UNITS, DAYS, STORAGE_KEYS } from '../../../js/lib/constants';
import { tiffinsApi } from '../../../js/slices/api_slices';

const { useSnackBarNotification, useDebounce } = Hooks;
const { useGetTiffinItemsQuery, useCreateTiffinItemMutation } = tiffinsApi;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '1px solid',
  p: 2,
};

export default function TiffinGoodForm({ item, isLoading, control, errors, action, watch, reset }) {
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit, setUnit] = useState('');

  const [open, setOpen] = useState(false);
  const [itemsChanged, setItemsChanged] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleClear = () => setDays([]);

  const [days, setDays] = useState(DAYS);
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [createItem, setCreateItem] = useState(false);

  const navigate = useNavigate();

  const debouncedTiffinInput = useDebounce(itemName, 250);

  const { data: tiffinItems,
    isSuccess: tiffinItemFetchSuccess,
    isLoading: tiffinItemFetchLoading } = useGetTiffinItemsQuery({
      page: 1,
      pageSize: 20,
      contains: { name: debouncedTiffinInput },
    }, { refetchOnMountOrArgChange: true, skip: debouncedTiffinInput ? false : true });

  const [addNewTiffinItem] = useCreateTiffinItemMutation();

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: 'items',
  });

  const [rows, setRows] = useState(fields?.map((field) => ({
    ...field,
    name: `${field.name}-${field.quantity}-${field.unit}`,
    days: field?.days.map((day) => DAYS.find(({ value }) => value === day).shortName)
  })));

  useEffect(() => {
    setRows(fields.map((item) => ({
      ...item,
      days: item?.days.map((day) => DAYS.find(({ value }) => value === day).shortName),
      name: `${item?.name}-${item?.quantity}-${item?.unit}`
    })));
  }, [fields]);

  useEffect(() => {
    if (tiffinItemFetchSuccess && tiffinItems?.data?.items?.length === 0) {
      setCreateItem(true);
    }
  }, [tiffinItemFetchSuccess]);

  const deleteItemHandler = (id) => {
    const itemIndex = fields.findIndex((item) => item.id === id);
    if (itemIndex > -1) {
      remove(itemIndex);
      setItemsChanged(true);
    }
  };

  const removeAll = () => {
    remove();
    setRows([]);
    setItemsChanged(true);
  };

  const columns = [
    {
      field: 'name',
      align: 'left',
      headerAlign: 'left',
      headerName: 'Name',
      width: 150,
      editable: false,
    },
    {
      field: 'days',
      headerName: 'Days',
      width: 280,
      editable: false,
      type: 'multiselect',
      valueOptions: DAYS.map(({ shortName }) => shortName),
    },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Actions',
      width: 90,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <IconButton
            key={id}
            edge="end"
            aria-label="delete"
            onClick={() => deleteItemHandler(id)} sx={{ p: 0 }}>
            <DeleteOutlineIcon />
          </IconButton>,
        ];
      },
    },
  ];

  const processRowUpdate = (newRow) => {
    const itemIndex = fields.findIndex((item) => item.id === newRow.id);
    if (itemIndex > -1) {
      update(itemIndex, newRow);
      const updatedRow = { ...newRow, isNew: false };
      const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
      setRows(updatedRows);
      return updatedRow;
    }
  };

  return (<>
    <Grid
      sx={{
        backgroundColor: 'white',
        borderRadius: '20px',
        border: '1px solid #E0E0E0',
        height: '100%',
      }}>
      <Grid item xs={12} container spacing={1}
        sx={{
          p: 2,
          height: 'inherit',
        }}>
        <Grid container sx={{ p: 2 }} spacing={1}>
          <Grid item container xs={12}>
            <Grid item xs={8}>
              <Typography color="secondary.contrastText.main">List of the goods</Typography>
            </Grid>
            <Grid container item xs={4} justifyContent='flex-end'>
              <Grid item xs="auto" container justifyContent='flex-end' sx={{
                height: 30,
              }}>
                <CoreButton
                  variant='contained'
                  fullWidth={false}
                  onClickHandler={handleOpen}
                >Add Item
                </CoreButton>
              </Grid>
            </Grid>
          </Grid>
          {fields.length > 0 && <Grid item xs={12} container>
            <Grid item xs={6}>
              <Typography fontWeight="bold" variant="h6">Items</Typography>
            </Grid>
            <Grid item xs={6} container justifyContent='flex-end'>
              <CoreButton
                disableRipple
                fullWidth={false}
                onClickHandler={() => removeAll()}
                sx={{
                  color: 'secondary.contrastText',
                  textDecoration: 'underline',
                }}
              >Clear Items</CoreButton>
            </Grid>
          </Grid>}
          <Grid item xs={12}>
            <Box
              sx={{
                mt: 1,
                'height': '65vh',
                'width': '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(error) => {
                  console.log({ error });
                }}
              />
            </Box>
          </Grid>
          {action === 'update' && (itemsChanged || watch('name') !== item?.name) && <Grid item xs={12}>
            <Alert
              severity="info"
              action={
                <Controller
                  control={control}
                  name="updateExistingSubscribers"
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      sx={{
                        color: 'secondary.contrastText',
                        '&.Mui-checked': {
                          color: 'secondary.contrastText',
                        },
                      }}
                    />
                  )}
                />
              }
            >
              <Typography fontWeight="bold">
                Apply item changes in existing customers subscribed to this tiffin
              </Typography>
            </Alert>
          </Grid>}
        </Grid>
        <Grid
          item
          container
          xs={6}
          justifyContent='flex-start'
          alignContent='flex-end'
        >
          <Grid item>
            <CoreButton
              fullWidth={true}
              onClickHandler={() => {
                reset({
                  name: '',
                  description: '',
                  items: [],
                  price: {
                    delivery: {
                      single: '',
                      monthly: '',
                      weekly: '',
                    },
                    pickUp: {
                      single: '',
                      monthly: '',
                      weekly: '',
                    },
                  }
                });
                sessionStorage.removeItem(STORAGE_KEYS.TIFFIN)
              }}
              variant="outlined"
              sx={{}}
            >
              Clear
            </CoreButton>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={6}
          spacing={2}
          justifyContent='flex-end'
          alignContent='flex-end'
        >
          <Grid item>
            <CoreButton
              fullWidth={true}
              onClickHandler={() => navigate(-1)}
              variant="contained"
            >
              Back
            </CoreButton>
          </Grid>
          <Grid item>
            <CoreButton
              isLoading={isLoading}
              type='submit'
              fullWidth={true}
              variant="contained"
            >
              {item ? 'Modify' : 'Add'}
            </CoreButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Modal
      open={open}
      keepMounted
      aria-labelledby="modal-modal-item-form"
    >
      <Box sx={style}>
        <Typography variant="h6" sx={{ mt: 1, mb: 1, color: 'secondary.contrastText.main' }}>
          Add item to tiffin
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} container spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={5}>
              <TuiAutoComplete
                id="name"
                name="name"
                freeSolo
                errors={errors}
                onChange={(event, item) => {
                  if (item) {
                    setCreateItem(false);
                    setQuantity(item?.quantity);
                    setUnit(item?.unit);
                    setItemName(item?.name);
                    item.label = item.name;
                  }
                }}
                inputValue={itemName}
                onInputChange={(event, newInputValue) => {
                  setItemName(newInputValue);
                }}
                labelKey='label'
                helperText="e.g Rice"
                label="Name"
                options={tiffinItems?.data?.items.map(({ name, quantity, unit }) => ({
                  name,
                  quantity,
                  unit,
                  label: `${name}-${quantity}-${unit}`,
                }))}
              />
            </Grid>
            <Grid item xs={3}>
              <BaseTextField
                id='quantity'
                size="small"
                label="Quantity"
                type="number"
                helperText="e.g 1"
                value={quantity}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TuiAutoComplete
                id="unit"
                name="unit"
                errors={errors}
                onChange={(event, item) => {
                  if (item) {
                    setUnit(item?.label);
                  } else {
                    setUnit('');
                  }
                }}
                value={{ label: '' || unit }}
                labelKey='label'
                helperText="e.g Bowl"
                label="Unit"
                options={Object.values(UNITS).map((item) => {
                  return {
                    label: item,
                  };
                })}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Days</InputLabel>
              <Select
                id="days"
                input={
                  <OutlinedInput
                    label="Chip"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleClear} edge="end">
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{ paddingRight: '40px' }} // Adjust padding as needed
                  />
                }
                multiple
                value={days}
                onChange={(e) => {
                  const selectedDays = e.target.value;
                  const sortedDays = selectedDays.sort((a, b) => a.value - b.value);
                  setDays(sortedDays);
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected
                      .map((item) => DAYS.find((day) => day.shortName === item.shortName))
                      .sort((a, b) => a.value - b.value)
                      .map((item) => (
                        <Chip key={item.shortName} label={item.shortName} />
                      ))}
                  </Box>
                )}
              >
                {DAYS.map((day) => (
                  <MenuItem
                    key={day.shortName}
                    value={day}
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: 'lightgrey !important',
                        color: 'black',
                      },
                    }}
                  >
                    {day.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} justifyContent='flex-end' container spacing={1}>
            <Grid item xs="auto">
              <CoreButton
                variant='contained'
                fullWidth={false}
                isLoading={tiffinItemFetchLoading}
                onClickHandler={handleClose}
              >Close
              </CoreButton>
            </Grid>
            <Grid item xs="auto">
              <CoreButton
                variant='contained'
                fullWidth={false}
                isLoading={tiffinItemFetchLoading}
                disabled={!itemName || !quantity || !unit}
                onClickHandler={() => {
                  const existingItem = rows.findIndex((item) => item?.name?.toLowerCase() === itemName?.toLowerCase());
                  if (existingItem > - 1) {
                    errorSnackBar({ message: 'Item already exists' });
                    return;
                  }
                  if (createItem) {
                    addNewTiffinItem({
                      name: itemName,
                      quantity,
                      unit,
                    });
                  }
                  append({ name: itemName, quantity, unit, days: days.map(({ value }) => value) });
                  successSnackBar({ message: 'Item added successfully' });
                  setQuantity('');
                  setUnit('');
                  setItemName('');
                  setItemsChanged(true);
                }}
              >Add
              </CoreButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  </>
  );
}
