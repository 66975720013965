import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Grid,
  Typography,
  Box,
} from '@mui/material';

import { TuiSpinner, Hooks, BasePagination, Table } from '../../../core';
import { tiffinsApi } from '../../../js/slices/api_slices';

const { useGetTiffinSubscribersQuery } = tiffinsApi;
const { useResponsivePageSize } = Hooks;

const headCells = [{
  id: 'name',
  title: 'Name',
}, {
  id: 'phoneNumber',
  title: 'Phone Number',
}, {
  id: 'tiffinLabel',
  title: 'Tiffin',
}];

export default function TiffinCustomersSelectionDrawer({ item, open, handleClose }) {
  const [page, setPage] = useState(1);
  const pageSize = useResponsivePageSize();

  const { data: assignedTiffins, isLoading: assignedTiffinsIsLoading } = useGetTiffinSubscribersQuery({
    'page': page,
    'pageSize': pageSize,
    'tiffin.id': item?.id,
    'sortKey': '+customer.firstName',
  }, { refetchOnMountOrArgChange: true, skip: !item?.id });

  const pageChangeHandler = (event, value) => {
    setPage(value);
  };

  const processedItems = assignedTiffins?.data?.items?.map((item) => {
    const { id, customer, tiffin } = item;
    return {
      name: `${customer?.firstName} ${customer?.lastName}`,
      phoneNumber: customer?.phoneNumber?.number,
      tiffin: tiffin?.name,
      id,
    };
  });

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: { width: 500 },
      }}
      open={open}
      onClose={handleClose}>
      {assignedTiffinsIsLoading && <TuiSpinner />}
      {!assignedTiffinsIsLoading && <Grid
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          height: '100%',
        }}>
        <Grid item xs={12} container spacing={1}
          sx={{
            p: 2,
            height: 'inherit',
          }}>
          <Grid item xs={12} container height='0%' spacing={1}>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <Typography fontWeight="bold" variant="h6">Customers</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex" flexDirection="column" alignItems="stretch" padding={1}
                sx={{
                  'height': '90vh',
                  'width': '100%',
                  '& .actions': {
                    color: 'text.secondary',
                  },
                  '& .textPrimary': {
                    color: 'text.primary',
                  },
                }}
              >
                <Table headers={headCells} data={processedItems} isLoading={assignedTiffinsIsLoading} selectedRow={() => { }} />
              </Box>
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ height: '100%', position: 'sticky', bottom: 60, marginTop: '60px' }}>
              <BasePagination
                data={assignedTiffins}
                pageSize={pageSize}
                page={page}
                pageChangeHandler={pageChangeHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>}
    </Drawer>
  );
}

TiffinCustomersSelectionDrawer.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};