import React, { useRef } from 'react';
import { Typography, InputAdornment, Grid, Badge } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

import BaseTextField from './base_text_field';
import CoreButton from './button';

export default function AppBar({
  title,
  searchHelperText,
  setSearchText,
  selectedItems = [],
  buttonClickHandler,
  buttonDisabled,
  buttonText = 'add',
  selectedItemsMenu,
  searchText = '',
  menuItems,
  filter
}) {
  const theme = useTheme();
  const searchInputRef = useRef(null);

  return (<Grid container>
    <Grid
      item
      xs={4}
      sx={{ mt: 1 }}>
      {
        typeof title === 'string' ? (
          <Typography variant="h6" fontWeight="bold" sx={{ pl: 1 }}>
            {title}
          </Typography>
        ) : React.isValidElement(title) ? (
          title
        ) : null
      }
    </Grid>
    <Grid item container justifyContent='flex-end' xs={8} spacing={1}>
      {!selectedItems?.length && <>
        {menuItems && <Grid item xs='auto'>
          {menuItems}
        </Grid>}
        {filter && <Grid item xs='auto'>
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            color="error"
            badgeContent={filter.filterCount}
            max={999}>
            <CoreButton
              variant="outlined"
              sx={{
                mt: 1.2,
              }}
              onClickHandler={() => filter.setShowFilterModal(!filter.showFilterModal)}
            >
              Filters
            </CoreButton>
          </Badge>
          {filter.filterModal}
        </Grid>}
        {setSearchText && <Grid item xs='auto' justifyContent='flex-end' sx={{ ml: 0.5 }}>
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            badgeContent={searchText ? 1 : 0}
            color="error"
            invisible={!searchText}
          >
            <BaseTextField
              size="small"
              sx={{
                mt: 1,
                width: '100%',
                '& .MuiInputBase-input': {
                  padding: theme.spacing(1, 1, 1, 0),
                  // vertical padding + font size from searchIcon
                  transition: theme.transitions.create('width'),
                  [theme.breakpoints.up('sm')]: {
                    width: '0ch',
                    '&:focus': {
                      width: '20ch',
                    },
                  },
                },
              }}
              value={searchText || ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon onClick={() => searchInputRef.current.focus()} />
                  </InputAdornment>
                ),
              }}
              inputRef={searchInputRef}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              placeholder={searchHelperText}
            />
          </Badge>
        </Grid>}
        <Grid item sx={{ mt: 1 }} xs='auto'>
          {buttonClickHandler && typeof buttonClickHandler === 'function' &&
            <CoreButton
              variant="contained"
              disabled={buttonDisabled}
              onClickHandler={buttonClickHandler}
            > {buttonText}
            </CoreButton>}
        </Grid>
      </>}
      {selectedItems && selectedItems.length > 0 && selectedItemsMenu}
    </Grid>
  </Grid>);
}

AppBar.propTypes = {
  searchText: PropTypes.string,
};

