import React, { useState, useEffect } from 'react';
import {
    Drawer,
    Grid,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { TuiSpinner, GoogleMap, CoreButton, Hooks } from '../../../core';

const { useSnackBarNotification } = Hooks;

export default function RouteAreaDrawer({ item, viewOnly, open, handleClose, isLoading = false }) {
    const settings = useSelector((store) => store?.settings?.settings);
    const { setValue, getValues, watch } = useFormContext();
    const [coordinates, setCoordinates] = useState(getValues('deliveryArea.coordinates') || []);
    const { errorSnackBar } = useSnackBarNotification();
    const [shape, setShape] = useState(null);

    const handleDrawingManagerSave = async (poly, type) => {
        const polyArray = poly.getPath().getArray();
        if (polyArray.length < 3) {
            errorSnackBar({ message: 'Please draw a valid polygon' });
            return;
        }
        let coordinates = [];
        polyArray.forEach(function (path) {
            coordinates.push([path.lng(), path.lat()]);
        });
        setCoordinates(coordinates);
        coordinates.push([coordinates[0][0], coordinates[0][1]]);
        setValue('deliveryArea', { type, coordinates });
    };

    const handleDrawingManagerClear = () => {
        if (shape) {
            shape.setMap(null);
            setShape(null);
        }
        setValue('deliveryArea', { type: null, coordinates: [] });
        setCoordinates([]);
    };


    useEffect(() => {
        if (getValues('deliveryArea.coordinates')) {
            setCoordinates(getValues('deliveryArea.coordinates'));
        }
    }, [watch('deliveryArea.coordinates')]);

    const total = item?.deliveryArea?.coordinates?.[0]?.length;
    const expectedCentreIndex = Math.floor(total / 2);
    const expectedCentreObject = item?.deliveryArea?.coordinates?.[0]?.[expectedCentreIndex];
    let mapStartAddress = {};
    if (expectedCentreObject?.[0]) {
        mapStartAddress = {
            coordinates: {
                latitude: expectedCentreObject?.[1],
                longitude: expectedCentreObject?.[0]
            }
        }
    } else {
        mapStartAddress = settings?.address;
    }

    return (
        <Drawer
            anchor="right"
            PaperProps={{
                sx: {
                    width: '60vw',
                },
            }}
            open={open}
            onClose={handleClose}>
            {isLoading && <TuiSpinner />}
            <Grid item xs={12} container spacing={1} sx={{ p: 1, pb: 2, pr: 2 }}>
                <Grid item>
                    <GoogleMap
                        showSearch={true}
                        width='58vw'
                        height='75vh'
                        drawingManager={{
                            onSave: handleDrawingManagerSave,
                            shape,
                            setShape
                        }}
                        polygonCords={coordinates}
                        startAddress={mapStartAddress}
                    />
                </Grid>
                <Grid item xs={12} container>
                    <Grid
                        item
                        xs={6}
                        container
                        spacing={2}
                        alignContent='flex-end'
                    >
                        <Grid item>
                            <CoreButton
                                fullWidth={true}
                                onClickHandler={() => handleClose()}
                                variant="contained"
                            >
                                Back
                            </CoreButton>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        container
                        spacing={2}
                        justifyContent='flex-end'
                        alignContent='flex-end'
                    >
                        <Grid item>
                            <CoreButton
                                disabled={viewOnly}
                                fullWidth={true}
                                onClickHandler={handleDrawingManagerClear}
                                variant="outlined"
                                sx={{}}
                            >
                                Clear
                            </CoreButton>
                        </Grid>
                        <Grid item>
                            <CoreButton
                                disabled={viewOnly}
                                fullWidth={true}
                                onClickHandler={() => handleClose()}
                                variant="contained"
                            >
                                Save
                            </CoreButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer >
    );
}
