import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import {
  IconButton,
  Divider,
  Drawer,
  Box,
  List,
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';

import LogoutComponent from '../../login/components/logout_component';
import Logo from '../../../assets/images/dinespot_white_logo.png';
import { drawerNavLocations } from './drawer_locations';
import { SIDE_DRAWER_WIDTH } from '../../../js/lib/constants';

const openedMixin = (theme) => ({
  width: SIDE_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: SIDE_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function HomePageDrawer() {
  const location = useLocation();
  const [showLogout, setShowLogout] = useState(false);
  const [openedNestedRoute, setOpenNestedRoute] = useState('');
  const [open, setOpen] = useState(true);
  const [selectedPath, setSelectedPath] = useState(location?.pathname);
  const roles = useSelector((store) => store?.roles.roles);
  const navigate = useNavigate();

  const handleDrawer = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AppDrawer variant="permanent" open={open} PaperProps={{
          sx: {
            backgroundColor: 'primary.main',
            color: 'white',
          },
        }}>
          <DrawerHeader>
            <IconButton onClick={handleDrawer} sx={{ color: 'white' }}>
              {open ? <>
                <img alt='logo' src={Logo} height='40' width='130' />
                <ChevronLeftIcon />
              </> :
                <MenuIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ borderColor: 'whitesmoke' }} />
          <List>
            {drawerNavLocations.map(({ path, name, icon, rolesAvailability, routes }) => (
              <ListItem
                key={name}
                disablePadding
                sx={{ display: 'block' }}
              >
                {roles && (roles[rolesAvailability]?.read || rolesAvailability === 'settings') && <>
                  <ListItemButton
                    sx={{
                      'minHeight': 48,
                      'justifyContent': open ? 'initial' : 'center',
                      'px': 2.5,
                      'backgroundColor': !routes && path === selectedPath ? 'grey' : 'transparent',
                      '&:hover': {
                        'backgroundColor': 'lightgrey',
                        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                          color: 'black',
                        },
                      },
                    }}
                    onClick={() => {
                      if (routes) {
                        if (openedNestedRoute === path) {
                          setOpenNestedRoute('');
                        } else {
                          setOpenNestedRoute(path);
                        }
                      } else {
                        setSelectedPath(path);
                        setOpenNestedRoute('');
                        navigate(path);
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        color: 'white',
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                    {routes && <>{openedNestedRoute === path ? <ExpandLess /> : <ExpandMore />}</>}
                  </ListItemButton>
                  {routes && routes.map((nestedItem) =>
                   {
                    return ( <Collapse key={nestedItem?.name} in={openedNestedRoute.startsWith(path)} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {roles?.[rolesAvailability]?.[nestedItem?.rolesAvailability]?.read && <ListItemButton
                        sx={{
                          'pl': 4,
                          'backgroundColor': `${path}/${nestedItem.path}` === selectedPath ? 'grey' : 'transparent',
                          '&:hover': {
                            'backgroundColor': 'lightgrey',
                            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                              color: 'black',
                            },
                          },
                        }}
                        onClick={() => {
                          const nestedPath = `${path}/${nestedItem.path}`;
                          navigate(nestedPath);
                          setSelectedPath(nestedPath);
                        }}>
                        {nestedItem?.icon && <ListItemIcon
                          sx={{
                            'minWidth': 0,
                            'mr': open ? 1 : 'auto',
                            'justifyContent': 'center',
                            'color': 'white',
                          }}
                        >
                          {nestedItem.icon}
                        </ListItemIcon>}
                        <ListItemText primary={nestedItem?.name} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>}
                    </List>
                  </Collapse>)
                   }
                  )}
                </>
                }
              </ListItem>
            ))}
          </List>
          <Box sx={{ mt: 'auto' }}>
          <List>
            <ListItem
              disablePadding
              sx={{
                display: 'block'
              }}
            >
              <ListItemButton
                sx={{
                  'minHeight': 48,
                  'justifyContent': open ? 'initial' : 'center',
                  'px': 2.5,
                  'backgroundColor': `/whats-new` === selectedPath ? 'grey' : 'transparent',
                  '&:hover': {
                    'backgroundColor': 'lightgrey',
                    '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                      color: 'black',
                    },
                  },
                }}
                onClick={() => {
                  setShowLogout(true);
                  setOpen(false);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : 'auto',
                    color: 'white',
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  primary={'Logout'}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
          </Box>
        </AppDrawer>
      </Box>
      <LogoutComponent displayModal={showLogout} setDisplayModal={setShowLogout} />
    </>
  );
}
